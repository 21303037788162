import { ProductPrice } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import useCardImageUrl from '../../hooks/useCardImageUrl';
import $window from '../../tools/window';
import { priceType } from '../types';
import style from './cards.module.scss';

export default function SimpleProductCard({
  brand = 'anf',
  imageHostName = 'https://img.abercrombie.com',
  index,
  item,
  handleProductCardClick = () => {},
  listPosition = 0,
  setIsQuickViewOpen = () => {},
  showQuickviewOnClick = false,
}) {
  const imageRef = useRef();

  const imageId = item.primaryFaceOutImage ?? `${item.imageId}_prod1`;
  const {
    handleOnError,
    imageUrl,
  } = useCardImageUrl(brand, item.imageId, imageHostName, imageRef, item.primaryFaceOutImage);

  const showQuickview = (event) => {
    event.preventDefault();
    setIsQuickViewOpen(true);
    const quickviewEvent = new CustomEvent('quickview:openModal', {
      detail: {
        productId: item.productId,
        collectionId: item?.collection,
        seq: item?.defaultSwatchSequence,
        productName: item.name,
        imageId,
        productBrand: brand,
        faceout: item.primaryFaceOutImage?.replace(/[A-Z\d\-_]/g, ''),
        returnFocus: event.currentTarget,
      },
    });
    /**
     * There's a possibility that something goes wrong and the QV doesn't open,
     * which will mess up our state.
     * This is the best we can do since we're not getting any acknowledgement
     * as to whether or not the QV opened in response to our event.
     */
    try {
      $window.dispatchEvent(quickviewEvent);
    } catch (error) {
      // TODO: Log this?
    }
  };

  useEffect(() => {
    const dsModalClose = (event) => {
      if (event.detail.id === 'product-quickview__modal') {
        setIsQuickViewOpen(false);
      }
    };
    $window.addEventListener('dsModal:Close', dsModalClose);
    return () => {
      $window.removeEventListener('dsModal:Close', dsModalClose);
    };
  }, [setIsQuickViewOpen]);

  const activeCard = index === listPosition;

  return (
    <div
      key={item.productId}
      aria-label={item.name}
      aria-selected={activeCard}
      className={`${style.cardWrapper} ${activeCard ? style.selected : ''}`}
      data-index={index}
      data-type="products"
      data-value={item.name}
      role="option"
    >
      <a
        className={style.card}
        href={item.productPageUrl ?? '#'}
        onClick={showQuickviewOnClick ? showQuickview : handleProductCardClick}
      >
        <img
          ref={imageRef}
          alt={item.name}
          className={style.img}
          onError={handleOnError}
          src={imageUrl}
          width="400"
        />
        <span className={style.productName}>
          {item.name}
        </span>
        {item.price ? (
          <ProductPrice
            description={item.price.description}
            discountPrice={item.price.discountPrice}
            discountText={item.price.discountText}
            originalPrice={item.price.originalPrice}
            variant={item.price.variant}
          />
        ) : null}
        {item.memberPrice && (
          <ProductPrice
            description={item.memberPrice.description}
            discountText={item.memberPrice.discountText}
            originalPrice={item.memberPrice.originalPrice}
            variant={item.memberPrice.variant}
          />
        )}
      </a>
    </div>

  );
}

SimpleProductCard.propTypes = {
  brand: PropTypes.string,
  imageHostName: PropTypes.string,
  item: PropTypes.shape({
    collection: PropTypes.string,
    defaultSwatchSequence: PropTypes.string,
    imageId: PropTypes.string,
    memberPrice: priceType,
    name: PropTypes.string,
    price: priceType,
    primaryFaceOutImage: PropTypes.string,
    productId: PropTypes.string,
    productPageUrl: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  listPosition: PropTypes.number,
  setIsQuickViewOpen: PropTypes.func,
  handleProductCardClick: PropTypes.func,
  showQuickviewOnClick: PropTypes.bool,
};

import { useQuery } from '@apollo/client';
import { Button, ButtonGroup } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import useDigitalData from '../../hooks/useDigitalData';
import useTranslatedText from '../../hooks/useTranslatedText';
import { IMAGE_HOST_NAME } from '../../queries';
import { DD_SEARCH_FLYOUT_IMAGE_SUGGESTIONS } from '../DigitalDataProvider';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import listStyle from '../SearchDefaultView/SearchDefaultView.module.scss';
import SimpleProductCard from '../SimpleProductCard/SimpleProductCard';
import style from './AutoSuggest.module.scss';
import AutoSuggestSubList from './AutoSuggestSubList';

export default function AutoSuggestResult({
  isFormFocused = false,
  isProcessing = false,
  listItemSubmitHandler,
  listPosition,
  productTypesData = [],
  productData = [],
  searchTerm = undefined,
  searchLabel = 'Search',
}) {
  const processing = useTranslatedText('processing', { fallback: 'Processing' });
  const products = useTranslatedText('autoSuggestProducts', { fallback: 'Products' });
  const suggestions = useTranslatedText('autoSuggestSuggestions', { fallback: 'Suggestions' });
  const seeAllResults = useTranslatedText('autoSuggestAllResults', { fallback: 'See all results for' });

  const prepareSimpleCardData = (item) => ({
    imageId: item.imageId,
    name: item.name,
    // default to prod1 until elastic search api provides more consistent data
    primaryFaceOutImage: `${item.imageId}_prod1`,
  });
  const {
    [DD_SEARCH_FLYOUT_IMAGE_SUGGESTIONS]: hasProductImagesEnabled,
  } = useDigitalData([
    DD_SEARCH_FLYOUT_IMAGE_SUGGESTIONS,
  ]);
  const queryData = useQuery(IMAGE_HOST_NAME, {
    skip: !hasProductImagesEnabled,
  });

  if (queryData.loading) {
    return (
      <div
        aria-busy="true"
        aria-live="polite"
        className={`auto-suggest-list-menu-wrapper fixed-list-container ${listStyle.listWrapper}`}
        data-expanded={!!isFormFocused}
        data-focused={!!isFormFocused}
        id="auto-suggest-desktop-list"
        tabIndex="-1"
      >
        <h2 className="h3">{LOADING_MESSAGE}</h2>
      </div>
    );
  }
  if (queryData.error) {
    return (
      <div
        aria-busy="true"
        className={`auto-suggest-list-menu-wrapper fixed-list-container ${listStyle.listWrapper}`}
        data-expanded={!!isFormFocused}
        data-focused={!!isFormFocused}
        id="auto-suggest-desktop-list"
        role="alert"
        tabIndex="-1"
      >
        <h2 className="h3">{ERROR_MESSAGE}</h2>
      </div>
    );
  }

  return (
    <div
      aria-label={searchLabel}
      className={`auto-suggest-list-menu-wrapper fixed-list-container ${listStyle.listWrapper}`}
      data-expanded={!!isFormFocused}
      data-focused={!!isFormFocused}
      id="auto-suggest-desktop-list"
      role="listbox"
      tabIndex="-1"
    >
      <div className={listStyle.listContainer} data-testid="auto-suggest-list-menu-wrapper">
        {productTypesData?.length > 0 ? (
          <>
            <div data-testid="auto-suggest-list-suggestions">
              <h2 className="h3">{suggestions.value}</h2>
              <AutoSuggestSubList
                data={productTypesData}
                listItemSubmitHandler={listItemSubmitHandler}
                listPosition={listPosition}
                searchTerm={searchTerm}
                suggestionType="suggestions"
              />
            </div>
          </>
        ) : null}
        {productData?.length > 0 ? (
          <>
            <div data-testid="auto-suggest-list-products">
              <h2 className="h3">{products.value}</h2>
              {hasProductImagesEnabled ? (
                <div className={style.grid}>
                  {productData.slice(0, 4).map((item, index) => (
                    <SimpleProductCard
                      key={item.imageId}
                      brand="anf"
                      handleProductCardClick={listItemSubmitHandler}
                      imageHostName={queryData?.data.imageHost.imageHostName}
                      index={index}
                      item={prepareSimpleCardData(item)}
                      listPosition={listPosition - (productTypesData?.length ?? 0)}
                    />
                  ))}
                </div>
              ) : (
                <AutoSuggestSubList
                  data={productData}
                  listItemSubmitHandler={listItemSubmitHandler}
                  listPosition={listPosition - (productTypesData?.length ?? 0)}
                  searchTerm={searchTerm}
                  suggestionType="products"
                />
              )}
            </div>
          </>
        ) : null}
        <ButtonGroup variant="vertical-spaced">
          <Button
            isFullWidth
            isProcessing={isProcessing}
            type="submit"
            value={searchTerm}
            variant="secondary"
          >
            {isProcessing ? `${processing.value}...` : `${seeAllResults.value} "${searchTerm}"`}
          </Button>
        </ButtonGroup>
        {isProcessing && (productTypesData?.length > 0 || productData?.length > 0) ? (
          <div className={style.spinWrapper}>
            <div className={style.dotSpin} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

AutoSuggestResult.propTypes = {
  productTypesData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  isFormFocused: PropTypes.bool,
  isProcessing: PropTypes.bool,
  listItemSubmitHandler: PropTypes.func.isRequired,
  listPosition: PropTypes.number.isRequired,
  productData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  searchLabel: PropTypes.string,
  searchTerm: PropTypes.string,
};

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import sortDepartmentOptions from '../../tools/sortDepartmentOptions';
import DEPARTMENT_DATA from '../DepartmentSelector/queries';
import SuggestedButtons from './DepartmentButton';
import style from './SearchErrorPage.module.scss';

function SuggestDepartments({
  activeDepartment,
  searchTerm,
  onDepartmentChange,
}) {
  const { data: departmentData } = useQuery(DEPARTMENT_DATA);
  const departmentOptions = sortDepartmentOptions(departmentData?.departments || []);
  const newOptions = departmentOptions?.filter((department) => department.id !== activeDepartment && department.id !== 'All');
  const activeDepartmentName = departmentOptions?.find(
    (department) => department.id === activeDepartment,
  )?.name;
  const noResultsText = useTranslatedText('relatedResults', { fallback: 'Related results:' });
  const noResultsFor = useTranslatedText('noMatchFoundForInDepartment', {
    fallback: `No results found for "${searchTerm}" in ${activeDepartmentName}.`,
    replacements: [searchTerm, activeDepartmentName],
  });

  return (
    <div className={style.wrapper}>
      <h3 aria-live="assertive" className="search-title h3">{noResultsFor.value}</h3>
      <div className={style.buttonGroup}>
        <span>{noResultsText.value}</span>
        {newOptions?.map((department) => (
          <SuggestedButtons
            key={department.id}
            departmentId={department.id}
            departmentName={department.name}
            onDepartmentChange={onDepartmentChange}
            searchTerm={searchTerm}
          />
        ))}
      </div>
    </div>
  );
}
SuggestDepartments.propTypes = {
  activeDepartment: PropTypes.string,
  searchTerm: PropTypes.string,
  onDepartmentChange: PropTypes.func,
};

SuggestDepartments.defaultProps = {
  activeDepartment: '',
  searchTerm: '',
  onDepartmentChange: () => {},
};

export default SuggestDepartments;

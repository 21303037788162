import {
  arrayOf, func, number, shape, string,
} from 'prop-types';
import React from 'react';
import ClickedButtons from './ClickedButtons';
import style from './SuggestedFilters.module.scss';
import SuggestionButtons from './SuggestionButtons';

export default function SuggestedFilters({
  addSuggestion = () => {},
  clickedSuggestions = new Set(),
  numberOfSingleSuggestions = 5,
  numberOfMultiSuggestions = 3,
  removeSuggestion = () => {},
  searchTerm,
  suggestionObject = {
    rankedWords: { words: [] },
    rankedPhrases: { phrases: [] },
  },
}) {
  const phraseString = suggestionObject?.rankedPhrases?.phrases?.slice(0, numberOfMultiSuggestions)?.join(' ');
  const showSuggestions = searchTerm.split(' ').length < 4;
  const showPhraseButtons = showSuggestions && phraseString;

  const clickedButtons = (
    <ClickedButtons
      clickedSuggestions={clickedSuggestions}
      removeSuggestion={removeSuggestion}
    />
  );

  const wordButtons = showSuggestions && numberOfSingleSuggestions > 0 ? (
    <SuggestionButtons
      addSuggestion={addSuggestion}
      clickedSuggestions={clickedSuggestions}
      dupeCheckString={showPhraseButtons ? phraseString : null}
      numberOfSuggestions={numberOfSingleSuggestions}
      suggestions={suggestionObject?.rankedWords?.words}
    />
  ) : null;

  const phraseButtons = showPhraseButtons && numberOfMultiSuggestions > 0 ? (
    <SuggestionButtons
      addSuggestion={addSuggestion}
      clickedSuggestions={clickedSuggestions}
      numberOfSuggestions={numberOfMultiSuggestions}
      suggestions={suggestionObject?.rankedPhrases?.phrases}
    />
  ) : null;

  return (
    <>
      <div className={style.suggestedFilterBar}>
        {clickedButtons}
        {phraseButtons}
        {wordButtons}
      </div>
    </>
  );
}

SuggestedFilters.propTypes = {
  addSuggestion: func,
  clickedSuggestions: shape({
    size: number,
  }),
  suggestionObject: shape({
    rankedWords: shape({
      words: arrayOf(string),
      wordObjects: arrayOf(shape({
        word: string,
        count: number,
      })),
    }),
    rankedPhrases: shape({
      phrases: arrayOf(string),
      phraseObjects: arrayOf(shape({
        phrase: string,
        count: number,
      })),
    }),
  }),
  removeSuggestion: func,
  searchTerm: string.isRequired,
  numberOfSingleSuggestions: number,
  numberOfMultiSuggestions: number,
};

import $window from './window';

const getUrlSearchParamString = (params) => {
  const {
    departmentId,
    initialDepartmentId,
    facet,
    filter,
    highPrice,
    lowPrice,
    rows,
    searchTerm,
    sort,
    start,
  } = params;

  const urlSearchParams = new URLSearchParams($window.location.search);
  const departmentCategoryId = 'departmentCategoryId';

  // delete all facets before appending them to the url
  urlSearchParams.delete('facet');
  urlSearchParams.delete('filter');
  urlSearchParams.delete('highPrice');
  urlSearchParams.delete('lowPrice');

  facet.forEach((facetItem) => {
    const facetSplitArr = facetItem.split(':');
    urlSearchParams.append('facet', `${facetSplitArr[0].replace(/[(),"]/g, '')}:${facetSplitArr[1]}`);
  });

  urlSearchParams.delete('departmentId');
  if (departmentId) {
    urlSearchParams.set(urlSearchParams.has(departmentCategoryId) ? departmentCategoryId : 'departmentId', departmentId);
  }

  if (initialDepartmentId === null) {
    urlSearchParams.delete('initialDepartmentId');
  }

  urlSearchParams.delete('sort');
  if (sort) {
    urlSearchParams.set('sort', sort);
  }

  if (searchTerm) {
    urlSearchParams.set('searchTerm', searchTerm);
  }

  if (filter) {
    urlSearchParams.set('filter', filter);
  }
  if (highPrice) {
    urlSearchParams.set('highPrice', highPrice);
  }
  if (lowPrice) {
    urlSearchParams.set('lowPrice', lowPrice);
  }

  urlSearchParams.set('filtered', true);
  urlSearchParams.set('rows', (rows) || '90');
  urlSearchParams.set('start', (start) || '0');
  urlSearchParams.sort();
  return urlSearchParams.toString();
};

const updateUrl = (params) => {
  const { persistHistoryState } = params;
  $window.history.pushState(persistHistoryState ? $window.history.state : {}, '', `${$window.location.pathname}?${getUrlSearchParamString(params)}`);
};

export default updateUrl;

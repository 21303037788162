/* eslint-disable no-param-reassign */
import { useMemo } from 'react';
import { IMAGE_SIZE_POLICY } from '../tools/constants';
import getImageUrl from '../tools/getImageUrl';
import getNoImageFoundImageId from '../tools/getNoImageFoundImageId';
import tryImageLoad from '../tools/tryImageLoad';

const useCardImageUrl = (brand, imageId, imageHostName, imageRef, primaryFaceOut) => {
  const filename = primaryFaceOut ?? `${imageId}_prod1`;
  const imageUrl = getImageUrl(imageHostName, filename, IMAGE_SIZE_POLICY.MEDIUM);
  const noImageFoundImgUrl = useMemo(() => getImageUrl(
    imageHostName, getNoImageFoundImageId(brand), IMAGE_SIZE_POLICY.MEDIUM,
  ), [brand, imageHostName]);

  const handleOnError = () => {
    imageRef.current.onerror = null; // prevents looping
    const prodImageUrl = getImageUrl(imageHostName, imageId, IMAGE_SIZE_POLICY.MEDIUM);
    tryImageLoad(prodImageUrl)
      .then(() => { imageRef.current.src = prodImageUrl; })
      .catch(() => {
        tryImageLoad(noImageFoundImgUrl)
          .then(() => { imageRef.current.src = noImageFoundImgUrl; })
          // eslint-disable-next-line no-console
          .catch(() => console.log('No image could be loaded'));
      });
  };
  return { handleOnError, imageUrl };
};

export default useCardImageUrl;

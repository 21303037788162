import { useEffect } from 'react';

const useDesignSystemInit = (containerRef, dependencies = []) => {
  /* global ANF */

  useEffect(() => {
    if (containerRef.current && typeof ANF !== 'undefined') {
      ANF.globalV2?.designsystem?.init?.();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, ...dependencies]);
};

export default useDesignSystemInit;

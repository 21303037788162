import { Button } from 'anf-core-react';
import {
  bool, string, func, arrayOf, shape,
} from 'prop-types';
import React from 'react';
import AppendIcon from './AppendIcon';
import style from './SuggestionList.module.scss';

/**
 * SuggestionList component
 * @param {Object} props
 * @param {Number} props.listPosition - current higlighted position in the list
 * @param {Array} props.suggestions - list of suggestions
 * @param {Function} props.setSearchTerm - function to set search term
 * @param {Function} props.submitSearchTerm - function to submit search term
 * @returns {JSX.Element}
 */
export default function SuggestionList({
  blurHandler = () => {},
  focusOnSearchInput = () => {},
  isFormFocused = false,
  searchLabel = 'Search',
  setSearchTerm = () => {},
  onSearchTermChange = () => {},
  suggestions = [],
}) {
  const list = suggestions.map((suggestion, index) => {
    const name = suggestion?.name;
    const listItemSubmit = (event) => {
      if (event.type === 'click' || (event.key === 'Enter')) {
        onSearchTermChange({ value: name, submitMethod: 'user-initiated' });
        blurHandler();
      }
    };

    return (
      <div key={name} className={style.suggestionListItem}>
        <Button
          classList={`${style.suggestionButton} noBlur`}
          index={index}
          isFullWidth
          onClick={listItemSubmit}
          type="button"
          value={name}
          variant="borderless"
        >
          {name}
        </Button>
        <button
          aria-label="Add to Search"
          className={`${style.appendButton} noBlur`}
          onClick={() => {
            setSearchTerm(name);
            focusOnSearchInput();
          }}
          type="button"
          value={name}
        >
          <AppendIcon />
        </button>
      </div>
    );
  });

  return (
    <div
      aria-label={searchLabel}
      className={`${style.wrapper} ${isFormFocused ? style.expanded : style.closed}`}
      role="listbox"
      tabIndex="-1"
    >
      <div className={style.suggestionList}>
        <h2 className="h3">Suggestions</h2>
        {list}
      </div>
    </div>
  );
}

SuggestionList.propTypes = {
  blurHandler: func,
  focusOnSearchInput: func,
  isFormFocused: bool,
  searchLabel: string,
  setSearchTerm: func,
  onSearchTermChange: func,
  suggestions: arrayOf(shape({
    name: string,
  })),
};

const ARROW_WIDTH = 40;
const AUTOSUGGEST_THRESHOLD = 3;
const BROWSE_BETA_GROUP_PREFIX = 'BB_';
const CLICKED_SUGGESTION_KEY = 'clickedSuggestion';
const DEPT_SELECTION_KEY = 'deptSelection';
const DIGITAL_DATA_TIMEOUT = 5000;
const EDITABLE_SEARCH_SCROLL_OFFSET = 210;
const FILTERS_PER_FACET = 2;
const HEADER_SCROLL_OFFSET = 115;
const MAX_RECENT_SEARCHES = 5;
const MAX_SLIDER_LENGTH = 10;
const MAX_SLIDES_VISIBLE = 3;
const MAX_SUGGESTED_FILTERS = 6;
const RECENT_SEARCHES_KEY = 'recentSearches';
const RECS_DISPLAY_LIMIT = 4;
const SCROLL_THRESHOLD = '800px';
const SEEK_BETA_GROUP_PREFIX = 'SB_';
const SIMILAR_PRODUCT_ROWS = '10';
const SIMILAR_DEFAULT_RECIPE = 'default';
const SLIDE_WIDTH = 300;
const SMALLEST_POSSIBLE_BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
const SSR_IMAGE_COUNT = 12;
const PDP_SHOP_SIMILAR_MAX_ROWS = 16;
const PRICE_NEGATIVE_NUMBER_MESSAGE = 'Please enter a positive value to proceed.';
const PRICE_MIN_MAX_MESSAGE = 'Min price can’t exceed max price. Please try again.';
const PRODUCTS_PER_PAGE = '42';
const PRESERVED_DEPARTMENT_ID = 'departmentId';
const SEARCH_SEGMENT_NAME = 's';
const SEARCH_SEGMENT_VALUE = 'es2';

const ACTION = {
  CLEAR_ALL_PARAMETERS: 'CLEAR_ALL_PARAMETERS',
  CRS_UPDATE: 'CRS_UPDATE',
  DEPARTMENT_CHANGE: 'DEPARTMENT',
  FACET_TOGGLE: 'FACET_TOGGLE',
  LOCAL_STORE_TOGGLE: 'LOCAL_STORE_TOGGLE',
  PAGINATION: 'PAGINATION',
  PRICE: 'PRICE',
  SEARCHTERM: 'SEARCHTERM',
  SORT_UPDATE: 'SORT',
  SUGGESTED_SEARCHTERM: 'SUGGESTED_SEARCHTERM',
};

const IMAGE_SIZE_POLICY = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
};

const SHOP_SIMILAR_RECIPES = [
  SIMILAR_DEFAULT_RECIPE,
  'style',
  'color',
  'pdpStyle',
];
const LILY_AI_PRODUCT_ATTRIBUTES = [
  'AI_Product_Type',
  'AI_Style_Silhouette',
  'AI_Length',
  'AI_Color',
  'AI_Neck_Style',
  'AI_Sleeve_Length',
  'AI_Occasion',
  'AI_Dressing_Style',
];

const MAX_SIMILAR_CATEGORY_LENGTH = 3;
const LILY_OVERRIDES = [
  'hoodie',
];
const SIMILAR_FALLBACK_STYLE_NAMES = [
  'graphic',
];
const SEARCH_HELP_TOPICS = [
  'Order Status',
  'Returns & Exchanges',
  'Purchase History',
  'My Account',
  'Unsubscribe',
];

const FACET_DICTIONARY = {
  productCategories: 'ads_f36005_ntk_cs',
  sizes: 'ads_f43002_ntk_cs',
  colorFamily: 'colorFamily',
  lengths: 'ads_f43003_ntk_cs',
  lengthStyles: 'ads_f36001_ntk_cs',
  materials: 'ads_f41501_ntk_cs',
  styles: 'ads_f36501_ntk_cs',
  details: 'ads_f42001_ntk_cs',
  logos: 'ads_f12002_ntk_cs',
};

module.exports = {
  ACTION,
  ARROW_WIDTH,
  AUTOSUGGEST_THRESHOLD,
  BROWSE_BETA_GROUP_PREFIX,
  CLICKED_SUGGESTION_KEY,
  DEPT_SELECTION_KEY,
  DIGITAL_DATA_TIMEOUT,
  EDITABLE_SEARCH_SCROLL_OFFSET,
  SEARCH_SEGMENT_NAME,
  SEARCH_SEGMENT_VALUE,
  FILTERS_PER_FACET,
  HEADER_SCROLL_OFFSET,
  IMAGE_SIZE_POLICY,
  LILY_AI_PRODUCT_ATTRIBUTES,
  LILY_OVERRIDES,
  MAX_RECENT_SEARCHES,
  MAX_SIMILAR_CATEGORY_LENGTH,
  MAX_SLIDER_LENGTH,
  MAX_SLIDES_VISIBLE,
  MAX_SUGGESTED_FILTERS,
  PDP_SHOP_SIMILAR_MAX_ROWS,
  PRESERVED_DEPARTMENT_ID,
  PRICE_MIN_MAX_MESSAGE,
  PRICE_NEGATIVE_NUMBER_MESSAGE,
  PRODUCTS_PER_PAGE,
  RECENT_SEARCHES_KEY,
  RECS_DISPLAY_LIMIT,
  SCROLL_THRESHOLD,
  SEARCH_HELP_TOPICS,
  SEEK_BETA_GROUP_PREFIX,
  SHOP_SIMILAR_RECIPES,
  SIMILAR_DEFAULT_RECIPE,
  SIMILAR_FALLBACK_STYLE_NAMES,
  SIMILAR_PRODUCT_ROWS,
  SLIDE_WIDTH,
  SMALLEST_POSSIBLE_BLANK_IMAGE,
  SSR_IMAGE_COUNT,
  FACET_DICTIONARY,
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import BreakpointProvider from '../BreakpointProvider';
import DigitalDataProvider, {
  DD_DISABLE_SWATCH_HOVER,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
  DD_PAGINATION_TEST,
} from '../DigitalDataProvider';
import MiniGrid from './MiniGrid';

const MiniGridWrapper = (props) => (
  <BreakpointProvider>
    <DigitalDataProvider
      keys={[
        DD_DISABLE_SWATCH_HOVER,
        DD_HYPERLINK_DESC,
        DD_MODEL_IMAGERY_TEST,
        DD_PAGINATION_TEST,
      ]}
    >
      <MiniGrid {...props} />
    </DigitalDataProvider>
  </BreakpointProvider>
);

export default MiniGridWrapper;

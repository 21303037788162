import { gql, useQuery } from '@apollo/client';
import React from 'react';
import BrandTabs from '../BrandTabs';
import SkipLink from '../SkipLink/SkipLink';

const getStoreAttributeData = gql`
  query storeAttributes {
    config {
      hasDisabledBrandTabs: genericType(name: "hasDisabledBrandTabs")
    }
  }
`;

function Header() {
  const { data: storeAttributeData } = useQuery(getStoreAttributeData);

  return (
    <>
      <SkipLink />
      {!storeAttributeData?.config?.hasDisabledBrandTabs?.value && <BrandTabs />}
    </>
  );
}

export default Header;

import PropTypes from 'prop-types';
import React from 'react';

import BreakpointProvider from '../BreakpointProvider/BreakpointProvider';
import FooterWrapper from './FooterWrapper';

export default function Footer({
  brand,
  country: defaultCountry = '',
}) {
  return (
    <BreakpointProvider>
      <FooterWrapper brand={brand} defaultCountry={defaultCountry} />
    </BreakpointProvider>
  );
}

Footer.propTypes = {
  brand: PropTypes.string.isRequired,
  country: PropTypes.string,
};

import PropTypes from 'prop-types';
import React from 'react';
import {
  useLocalStorage,
} from 'react-use';
import useDigitalData from '../../hooks/useDigitalData';
import useTranslatedText from '../../hooks/useTranslatedText';
import { RECENT_SEARCHES_KEY, SEARCH_HELP_TOPICS } from '../../tools/constants';
import removeFalsyItems from '../../tools/removeFalsyItems';
import $window from '../../tools/window';
import { DD_SEARCH_HELP_TOPICS } from '../DigitalDataProvider';
import SearchDefaultList from './SearchDefaultList';
import listStyles from './SearchDefaultView.module.scss';

export default function SearchDefaultView({
  children = null,
  isFormFocused = false,
  listItemSubmitHandler = () => {},
  popularSearchesList,
  wrapperId = '',
}) {
  const {
    [DD_SEARCH_HELP_TOPICS]: helpTopicsFlag,
  } = useDigitalData([
    DD_SEARCH_HELP_TOPICS,
  ]);

  const popularSearchHeader = useTranslatedText('popularSearchesHeader', { fallback: 'Popular Searches' });
  const recentSearchesLabel = useTranslatedText('recentSearchesLabel', { fallback: 'Recent Searches' });
  const searchLabel = useTranslatedText('autoSuggestDesktopPlaceholder', { fallback: 'Search' });
  const isList = wrapperId === 'editable-search-list';
  const [recentSearchesList, setRecentSearchesList] = useLocalStorage(RECENT_SEARCHES_KEY, []);

  const onSubmit = (event) => {
    const { target } = event;
    const closestDataGroup = target?.closest('[data-type]');
    if (closestDataGroup?.dataset?.type === 'help topics' && $window.digitalData) {
      $window.digitalData.trigger('universal_click', {
        event_type: 'click',
        data_text: `Search-${target.value}`,
        data_action: 'click',
      });
    }

    if (event.type === 'click' || event.key === 'Enter') {
      listItemSubmitHandler(event.target);
    }
  };

  const removeRecentSearches = () => {
    setRecentSearchesList([]);
  };

  const listData = [
    recentSearchesList.length > 0 && {
      clearAllButtonHandler: removeRecentSearches,
      dataType: 'recent search',
      hasClearAllButton: true,
      headingLabel: recentSearchesLabel?.value,
      options: recentSearchesList,
    },
    popularSearchesList.length > 0 && {
      dataType: 'popular search',
      headingLabel: popularSearchHeader?.value,
      options: popularSearchesList,
    },
    helpTopicsFlag && {
      dataType: 'help topics',
      headingLabel: 'Helpful Links', // TODO: Add translated text for helpful links heading
      options: SEARCH_HELP_TOPICS, // TODO: Add translated text for helpful links options
    },
  ];

  return (
    <div
      aria-label={searchLabel.value}
      className={listStyles.listWrapper}
      data-expanded={!!isFormFocused}
      data-focused={!!isFormFocused}
      id={wrapperId}
      role="listbox"
      tabIndex="-1"
    >
      {removeFalsyItems(listData).map((list) => (
        <SearchDefaultList
          key={list.dataType}
          clearAllButtonHandler={list.clearAllButtonHandler}
          dataType={list.dataType}
          hasClearAllButton={list.hasClearAllButton}
          headingLabel={list.headingLabel}
          isList={isList}
          optionClickHandler={onSubmit}
          options={list.options}
        />
      ))}
      {children}
    </div>
  );
}

SearchDefaultView.propTypes = {
  children: PropTypes.node,
  isFormFocused: PropTypes.bool,
  listItemSubmitHandler: PropTypes.func.isRequired,
  popularSearchesList: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  wrapperId: PropTypes.string,
};

import { gql } from '@apollo/client';
import suggestionData from '../../../queries/fragments/SuggestedItem';

export const COMPLETIONS_V3 = gql`
${suggestionData}
query Suggestion($searchTerm: String, $genderTabsFlag: Boolean) {
  products: suggestion(searchTerm: $searchTerm, type: "products", genderTabsFlag: $genderTabsFlag) {
    suggestedItems {
      ...SuggestionData
      imageId
    }
  }
  productTypes: suggestion(searchTerm: $searchTerm, type: "productTypes", genderTabsFlag: $genderTabsFlag) {
    suggestedItems {
      ...SuggestionData
    }
  }
}
`;

export const GENDER_PRESERVATION = gql`
  query GenderPreservation {
    config {
      hasGenderPreservationEnabled: genericType(name: "hasGenderPreservationEnabled")
    }
  }
`;

export const GENDER_LIST = gql`
query GenderList {
  genderList {
    id
    name
    departmentIdentifiers
  }
}
`;

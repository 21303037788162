import { AccordionGroup } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import FacetsAccordion from './FacetsAccordion';
import PopularSearchesAccordion from './PopularSearchesAccordion';
import PriceAccordion from './PriceAccordion';

function FacetAccordions({
  brand,
  facet = [],
  facetData,
  hasPriceFilterEnabled = false,
  highPrice = '',
  isCategoryPage = false,
  lowPrice = '',
  onCheckBoxChange = () => {},
  onPriceChange = () => {},
  popularSearches = [],
}) {
  const filterFacetData = facetData.map((facetItem) => (
    <FacetsAccordion
      key={facetItem.id}
      brand={brand}
      facet={facet}
      facetItem={facetItem}
      onCheckBoxChange={onCheckBoxChange}
    />
  ));
  return (
    <AccordionGroup>
      {filterFacetData}
      { isCategoryPage && popularSearches.length > 0
      && <PopularSearchesAccordion brand={brand} popularSearches={popularSearches} />}
      { !isCategoryPage && hasPriceFilterEnabled ? (
        <PriceAccordion
          brand={brand}
          highPrice={highPrice}
          lowPrice={lowPrice}
          onPriceChange={onPriceChange}
        />
      ) : null}
    </AccordionGroup>
  );
}

FacetAccordions.propTypes = {
  // Required props
  brand: PropTypes.string.isRequired,
  facetData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  // Optional props
  hasPriceFilterEnabled: PropTypes.bool,
  highPrice: PropTypes.string,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  isCategoryPage: PropTypes.bool,
  lowPrice: PropTypes.string,
  onCheckBoxChange: PropTypes.func,
  onPriceChange: PropTypes.func,
  popularSearches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default FacetAccordions;

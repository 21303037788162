import { TagGroup } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import getPriceFilterLabel from '../../tools/getPriceFilterLabel';
import getSelectedFacets from '../../tools/getSelectedFacets';
import $window from '../../tools/window';
import style from './ProductGridTagGroup.module.scss';

const ProductGridTagGroup = ({
  facet = [],
  hasMobileGridHeader = false,
  hasFilters = false,
  highPrice = '',
  lowPrice = '',
  onPriceChange = () => {},
  setFacetState = () => {},
}) => {
  const tags = [];

  const handleTagClick = (tagId, tagLabel) => {
    setFacetState({
      facetKey: tagId,
      facetValue: tagLabel,
    });
    $window.dispatchEvent(new CustomEvent('tagRemove', { detail: { facetKey: tagId, facetValue: tagLabel } }));
  };

  getSelectedFacets(facet).forEach((facetValue, facetKey) => {
    facetValue.forEach((facetEntry) => tags.push(
      {
        label: facetEntry,
        removeButton: {
          labelText: `Remove ${facetEntry}`, // TODO
          onClick: () => { handleTagClick(facetKey, facetEntry); },
        },
      },
    ));
  });

  if (!!lowPrice || !!highPrice) {
    tags.push(
      {
        label: getPriceFilterLabel(lowPrice, highPrice),
        removeButton: {
          labelText: 'Remove Price Filter', // TODO
          onClick: () => { onPriceChange({ lowPrice: '', highPrice: '' }); },
        },
      },
    );
  }

  if (tags.length > 0) {
    return (
      <div className={
      `${style.wrapper} ${hasMobileGridHeader ? style.wrapperWithMfeGridHeader : style.wrapperWithCrsGridHeader}
      ${hasFilters ? style.wrapperWithFilters : ''}`
      }
      >
        <TagGroup tags={tags} />
      </div>
    );
  } return null;
};

ProductGridTagGroup.propTypes = {
  facet: PropTypes.arrayOf(PropTypes.string),
  hasFilters: PropTypes.bool,
  hasMobileGridHeader: PropTypes.bool.isRequired,
  highPrice: PropTypes.string,
  lowPrice: PropTypes.string,
  onPriceChange: PropTypes.func,
  setFacetState: PropTypes.func,
};

export default ProductGridTagGroup;

import { useEffect, useCallback, useRef } from 'react';
import getPageType from '../tools/getPageType';
import {
  resetDataStateForMarketingFilters,
  validateFilterElement,
  updateFacetValues,
  areFiltersRendered,
  getGenericFilterName,
  getLegacyFilterName,
} from '../tools/marketingFilterUtils';
import $window from '../tools/window';

/**
 * Custom hook for managing marketing filters.
 * Gets the marketing filter buttons on the page, adds event listeners, triggers events
 * @param {HTMLElement} espotRef - The reference to the ESPOT element.
 */
const useMarketingFilters = (espotRef, espotId) => {
  const getMarketingFilterElements = useCallback(() => document.querySelectorAll(`[data-espotname="${espotId}"] .js-content-filter`), [espotId]);

  const filterRefs = useRef({ filterValuesList: [], filterIdList: [] });

  const updateDataStateAndList = useCallback((filterElement, filterObj, hasMultiSelect) => {
    let elementIsSelected = false;

    if (!hasMultiSelect) {
      const marketingFilterElementsArr = Array.from(getMarketingFilterElements());

      const getSiblingElements = marketingFilterElementsArr.filter(
        (element) => element !== filterElement,
      );
      resetDataStateForMarketingFilters(getSiblingElements);
      filterRefs.current.filterIdList = [];
      filterRefs.current.filterValuesList = [];
    }

    if (filterElement.getAttribute('data-state') === 'filters-applied') {
      filterElement.setAttribute('data-state', '');
      filterRefs.current.filterValuesList = filterRefs.current.filterValuesList.filter(
        (item) => item !== filterObj.values,
      );
      filterRefs.current.filterIdList = filterRefs.current.filterIdList.filter(
        (item) => item !== filterObj.id,
      );
    } else {
      filterElement.setAttribute('data-state', 'filters-applied');
      elementIsSelected = true;
      filterRefs.current.filterValuesList.push(filterObj.values);
      filterRefs.current.filterIdList.push(filterObj.id);
    }

    return elementIsSelected;
  }, [getMarketingFilterElements]);

  const updateStateAndTriggerEvents = useCallback((filterElement, hasMultiSelect) => {
    if (areFiltersRendered() === false) return;
    // check the facet dictionary to get the API-agnostic facet name
    const genericFacetName = getGenericFilterName(filterElement.getAttribute('data-filter-id'));
    // check the facet dictionary to get the legacy facet name
    const legacyFacetName = getLegacyFilterName(filterElement.getAttribute('data-filter-id'));

    const filterObj = {
      values: filterElement.getAttribute('data-filter-values'),
      genericName: genericFacetName,
      legacyName: legacyFacetName,
    };

    const facetValues = updateFacetValues(filterObj);
    if (facetValues.length === 0) return;

    const marketingFiltersApplied = {
      detail: {
        marketingFilterType: (hasMultiSelect) ? 'multi' : 'single',
        facetValues,
        facetGenericName: filterObj.genericName,
        facetLegacyName: filterObj.legacyName,
        elementIsSelected: updateDataStateAndList(filterElement, filterObj, hasMultiSelect),
      },
    };

    $window.dispatchEvent(new CustomEvent('marketingFiltersClicked', marketingFiltersApplied));
    if ($window.digitalData) {
      const dataText = 'marketing_filters_applied';
      const eventType = 'click';
      $window.digitalData.trigger(`${getPageType()}_${dataText}_${eventType}`, {
        data_text: dataText,
        event_type: eventType,
        data_action: filterRefs?.current.filterValuesList.length > 1 ? 'multiple' : 'single',
        search_filter_applied_category: filterRefs.current?.filterValuesList?.join(','),
        search_filter_applied_value_name: filterRefs.current?.filterIdList?.join(','),
      });
    }
  }, [updateDataStateAndList]);

  useEffect(() => {
    /**
     * Handles click events on the filter elements.
     * @param {Event} event - The click event.
     */
    const handleClick = (event) => {
      const { target } = event;
      const filterElement = target.closest('.js-content-filter');

      if (filterElement && !validateFilterElement(filterElement)) return;

      const hasMultiSelect = target.closest('.js-experience-container')?.getAttribute('data-hasmultiselect') === 'true';

      updateStateAndTriggerEvents(filterElement, hasMultiSelect);
    };

    const clearMarketingFilters = () => {
      resetDataStateForMarketingFilters(getMarketingFilterElements());
      filterRefs.current.filterIdList = [];
      filterRefs.current.filterValuesList = [];
    };

    const removeSelectedState = (event) => {
      const { facetKey, facetValue } = event.detail;
      const marketingFilterElements = getMarketingFilterElements();
      marketingFilterElements.forEach((element) => {
        if (element.dataset.filterId === facetKey
            && element.dataset.filterValues.includes(facetValue)) {
          element.setAttribute('data-state', '');
        }
      });
    };

    const setDisabledMarketingFilters = () => {
      const disabledFacetsValueArr = Array.from(document.querySelectorAll('div.Filters input[type="checkbox"]:disabled')).map((facet) => facet.value);

      const marketingFilterElements = getMarketingFilterElements();

      marketingFilterElements.forEach((element) => {
        // need to remove disabled attribute, if facet is unselected
        element.removeAttribute('disabled');
        if (element.closest('.js-experience-container')?.getAttribute('data-hasdynamicfilters') === 'true') {
          element.dataset?.filterValues?.split(',').forEach((value) => {
            const filterIdToMatch = `${element.dataset.filterId},${value}`;
            if (disabledFacetsValueArr.includes(filterIdToMatch)) {
              element.setAttribute('disabled', 'disabled');
            }
          });
        }
      });
    };

    if (espotRef.current) {
      const marketingFilterElements = getMarketingFilterElements();
      marketingFilterElements?.forEach((button) => {
        button.addEventListener('click', handleClick);
      });

      $window.addEventListener('clearAllButtonClick', clearMarketingFilters);
      $window.addEventListener('facetChange', setDisabledMarketingFilters);
      $window.addEventListener('tagRemove', removeSelectedState);

      return () => {
        marketingFilterElements?.forEach((button) => {
          button.removeEventListener('click', handleClick);
        });

        $window.removeEventListener('tagRemove', removeSelectedState);
        $window.removeEventListener('clearAllButtonClick', clearMarketingFilters);
        $window.removeEventListener('facetChange', setDisabledMarketingFilters);
      };
    }
    return () => {};
  }, [espotRef, getMarketingFilterElements, updateStateAndTriggerEvents]);
};

export default useMarketingFilters;

import { useDebounce } from 'react-use';
import useDigitalData from '../../../hooks/useDigitalData';
import { AUTOSUGGEST_THRESHOLD } from '../../../tools/constants';
import {
  DD_SEARCH_INPUT_QUERY_DELAY,
} from '../../DigitalDataProvider';
import { useSuggestions } from '../../SearchBox/hooks/useQueries';

export default function useAutoSuggest(
  blurHandler,
  searchTerm,
) {
  const {
    [DD_SEARCH_INPUT_QUERY_DELAY]: debounceDelay,
  } = useDigitalData(
    [DD_SEARCH_INPUT_QUERY_DELAY],
  );
  const [getSuggestions, suggestions] = useSuggestions();
  const { combinedSuggestions } = suggestions;
  const suggestionsVisible = searchTerm.trim().length >= AUTOSUGGEST_THRESHOLD;
  const displayedData = suggestionsVisible ? combinedSuggestions : [];

  useDebounce(() => {
    if (searchTerm && searchTerm.length >= AUTOSUGGEST_THRESHOLD) {
      getSuggestions({ variables: { searchTerm } });
    }
  },
  debounceDelay,
  [searchTerm]);

  const keyDownHandler = (e) => {
    const { key } = e;

    const keyboardActions = {
      Escape: () => {
        blurHandler();
      },
    };
    keyboardActions[key]?.();
  };

  return {
    displayedData, keyDownHandler,
  };
}

import getGeoLocationCountry from './getGeoLocationCountry';
import getUPrefShipTo from './getUPrefShipTo';

/**
 * Get Country by Country Code (countryCode -> US -> defaultCountry)
 * @param {*} countryCode
 * @param {*} countriesData
 * @param {*} defaultCountry
 * @returns
 * */
const getCountryByCode = (countryCode, countriesData, defaultCountry) => {
  let countryObj;
  const countriesMap = new Map();
  countriesData.countries.forEach((country) => {
    countriesMap.set(country.countryCode, country);
  });

  if (countriesMap.has(countryCode)) {
    countryObj = countriesMap.get(countryCode);
  } else if (countriesMap.has('US')) {
    countryObj = countriesMap.get('US');
  } else {
    countryObj = countriesMap.get(defaultCountry);
  }
  return countryObj;
};

/**
 * Get Country with fallbacks (uPref -> Geo Location -> default)
 * @param {*} countriesData
 * @returns
 */
const getUserCountry = (countriesData, defaultCountry) => {
  const userCountryCode = getUPrefShipTo() || getGeoLocationCountry(defaultCountry);
  return getCountryByCode(userCountryCode, countriesData, defaultCountry);
};

export default getUserCountry;

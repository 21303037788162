import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import useDesignSystemInit from '../../hooks/useDesignSystemInit';
import useMarketingFilters from '../../hooks/useMarketingFilters';

const query = gql`
  query Content($espotId: String!, $singleEspot: Boolean, $storePreview: String) {
    content(espotId: $espotId, singleEspot: $singleEspot, storePreview: $storePreview) {
      espotId
      htmlContent
    }
  }
`;

export default function SingleAemEspot({
  espotId,
  content = null,
  storePreview = 'false',
}) {
  const { data } = useQuery(query, {
    variables: { espotId, singleEspot: true, storePreview },
    skip: !!content,
  });

  const htmlContent = content || data?.content?.htmlContent;
  const espotRef = useRef(null);

  useDesignSystemInit(espotRef);
  useMarketingFilters(espotRef, espotId);

  const genericContentClass = htmlContent
    ? 'genericESpot js-aem-content aem-content aem-enabled aem-mfe'
    : 'genericESpot genericESpot--empty';

  return (
    <div
      ref={espotRef}
      className={genericContentClass}
      dangerouslySetInnerHTML={{ __html: htmlContent ?? '' }}
      data-espotname={espotId}
      id={espotId}
    />
  );
}

SingleAemEspot.propTypes = {
  content: PropTypes.string,
  espotId: PropTypes.string.isRequired,
  storePreview: PropTypes.string,
};

import {
  Button,
  Icon,
  Toaster,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import React, {
  useContext, useRef, useState, useEffect,
} from 'react';
import { useSearchParam } from 'react-use';
import BreakpointContext from '../../context/breakpoint';
import useTranslatedText from '../../hooks/useTranslatedText';
import DomNodePortal from '../../tools/DomNodePortal';
import {
  AUTOSUGGEST_THRESHOLD,
} from '../../tools/constants';
import getPageType from '../../tools/getPageType';
import $window from '../../tools/window';
import RecommendationSlider from '../RecommendationSlider/RecommendationSlider';
import SimilarEntryPoint from '../ShopSimilar/SimilarEntryPoint';
import SearchBox from './SearchBox';
import style from './searchWrapper.module.scss';

// Need this for a DS bug where the onOpen event is called even when the toaster is closed
const handleToasterOpen = () => {
  if ($window.digitalData) {
    $window.digitalData.trigger('mfe-search-toaster-opened');
  }
};

const SearchWrapper = ({
  searchUrl = '',
}) => {
  const betaFlagGroup = useSearchParam('group');
  const [isSearchToasterOpen, setisSearchToasterOpen] = useState(false);
  const [showAlgonomyContainer, setShowAlgonomyContainer] = useState(true);
  const breakpoints = useContext(BreakpointContext);
  const mobileToasterButtonWrapper = useRef(null);
  const desktopToasterButtonWrapper = useRef(null);
  const { large: isDesktop } = breakpoints;
  const screenSizeVariables = isDesktop ? {
    ref: desktopToasterButtonWrapper,
  } : {
    ref: mobileToasterButtonWrapper,
  };

  const handleSearchIconClick = (event) => {
    if ($window.digitalData) {
      const pageType = getPageType();

      $window.digitalData.trigger(`${pageType}.search_icon.click`, {
        event_type: 'click',
        data_text: 'Search Icon',
        data_action: 'open',
      }, event);
    }
    setisSearchToasterOpen(true);
  };

  useEffect(() => {
    $window.addEventListener('search-toaster-open', () => setisSearchToasterOpen(true));

    return () => {
      $window.removeEventListener('search-toaster-open', () => setisSearchToasterOpen(true));
    };
  }, []);
  const handleSearchTermChange = (searchTerm) => {
    setShowAlgonomyContainer(!(searchTerm.trim().length >= AUTOSUGGEST_THRESHOLD));
  };

  const searchToasterLabel = useTranslatedText('searchToasterLabel', { fallback: 'Open Search' });
  const autoSuggestDesktopPlaceholder = useTranslatedText('autoSuggestDesktopPlaceholder', { fallback: 'Search' });
  const closeMenu = useTranslatedText('closeMenu', { fallback: 'Close' });
  const allDepartments = useTranslatedText('allDepartments', { fallback: 'All' });
  const searchToasterButton = (
    <div
      ref={screenSizeVariables.ref}
      className={style.wrapper}
      data-testid="search-toaster-button"
    >
      <Button
        classList={style.button}
        onClick={handleSearchIconClick}
        variant="borderless"
      >
        <Icon icon="search" labelText={searchToasterLabel.value} />
      </Button>
    </div>
  );
  const searchBox = (
    <SearchBox
      allDepartmentsTmnt={allDepartments.value}
      betaFlagGroup={betaFlagGroup}
      searchTermChange={handleSearchTermChange}
      searchUrl={searchUrl}
    />
  );
  const handleToasterClose = () => {
    setisSearchToasterOpen(false);
    screenSizeVariables.ref?.current?.firstChild?.focus();
  };
  return (
    <>
      <DomNodePortal targetNodeSelector=".rs-nav-bar--mfeSearchIcon">
        {searchToasterButton}
      </DomNodePortal>
      <SimilarEntryPoint />
      {searchToasterButton}
      <Toaster
        closeButtonLabel={closeMenu?.value}
        direction="from-right"
        heading={<div className="scope-1892"><h2 className="headline">{autoSuggestDesktopPlaceholder.value}</h2></div>}
        id="search-toaster"
        isOpen={isSearchToasterOpen}
        onClose={handleToasterClose}
        onOpen={handleToasterOpen}
      >
        {searchBox}
        <RecommendationSlider
          placementId="search_page.m_flyout_initial1|search_page.flyout_initial1"
          styles={`${style.recsWrapper} ${showAlgonomyContainer ? '' : style.hide}`}
        />
      </Toaster>
    </>
  );
};

SearchWrapper.propTypes = {
  searchUrl: PropTypes.string,
};

export default SearchWrapper;

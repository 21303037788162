import { gql, useQuery } from '@apollo/client';
import Icon from 'anf-core-react/components/Icon';
import IconButton from 'anf-core-react/components/IconButton';
import Logo from 'anf-core-react/components/Logo';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import Nav from '../SmallScreenNav';
import style from './SmallScreenNavBar.module.scss';

const getBrand = gql`
  query Category($categoryId: String!) {
    category(categoryId: $categoryId) {
      brand
      brandLogoType
      url
    }
  }
`;

const SmallScreenNavBar = ({
  categoryId,
  mySavesPath,
  country: defaultCountry = '',
}) => {
  const openMenu = useTranslatedText('openMenu', { fallback: 'Open Menu' });
  const openMyList = useTranslatedText('openMyList', { fallback: 'View My List' });
  const { data } = useQuery(getBrand, {
    variables: {
      categoryId,
    },
  });
  const [navOpen, setNavOpen] = useState(false);
  const handleNavToggle = () => setNavOpen((navState) => !navState);
  useEffect(() => {
    /* Other components still in CRS likely need this class to know when the nav is open */
    const { body } = document;
    if (navOpen) {
      body.classList.add('rs-nav--open');
    } else {
      body.classList.remove('rs-nav--open');
    }
  }, [navOpen]);
  if (data) {
    const {
      brand,
      url,
      brandLogoType,
    } = data.category;
    return (
      <>
        <header className={style.navBar}>
          {/* Left-aligned icons */}
          <div className={style.left}>
            <div
              className={style.toggle}
              data-testid="open-nav-menu-btn"
            >
              <IconButton
                icon="menu"
                labelText={openMenu.value}
                onClick={handleNavToggle}
                theme="inverse"
                variant="square"
              />
            </div>
            <div>
              { /* eslint-disable jsx-a11y/control-has-associated-label */}
              <a
                className={style.saves}
                href={mySavesPath}
              >
                <Icon
                  icon="heart"
                  labelText={openMyList.value}
                />
              </a>
            </div>
          </div>
          {/* Center-aligned logo */}
          <div className={style.center}>
            <a aria-label={brand} className={style.logo} href={url}>
              <Logo kind={brandLogoType} />
            </a>
          </div>
        </header>
        <Nav
          brand={brand}
          defaultCountry={defaultCountry}
          handleNavToggle={handleNavToggle}
          navOpen={navOpen}
        />
      </>
    );
  } return null;
};

SmallScreenNavBar.propTypes = {
  categoryId: PropTypes.string.isRequired,
  mySavesPath: PropTypes.string.isRequired,
  country: PropTypes.string,
};

export default SmallScreenNavBar;

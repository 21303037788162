/**
 * Returns a formatted price filter label based on the provided low and high prices.
 *
 * @param {string} lowPrice - The lowest price in the filter. Can be a string.
 * @param {string} highPrice - The highest price in the filter. Can be a string.
 * @returns {string} A string representing the price filter label.
 */

// TODO add TMNT labels for these?
const getPriceFilterLabel = (lowPrice, highPrice) => {
  let priceLabel = '';
  if (lowPrice && highPrice) {
    priceLabel = `$${lowPrice} - $${highPrice}`;
  } else if (!lowPrice && highPrice) {
    priceLabel = `Max: $${highPrice}`;
  } else if (lowPrice && !highPrice) {
    priceLabel = `Min: $${lowPrice}`;
  }
  return priceLabel;
};

export default getPriceFilterLabel;

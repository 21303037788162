import PropTypes from 'prop-types';
import React from 'react';
import BreakpointProvider from '../BreakpointProvider';
import SearchWrapper from './SearchWrapper';

function SearchDataProvider({
  productId: intlProductId = '',
  searchUrl: intlSearchUrl = '',
}) {
  return (
    <BreakpointProvider>
      <SearchWrapper
        productId={intlProductId}
        searchUrl={intlSearchUrl}
      />
    </BreakpointProvider>
  );
}

SearchDataProvider.propTypes = {
  productId: PropTypes.string,
  searchUrl: PropTypes.string,
};

export default SearchDataProvider;

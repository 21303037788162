import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import SingleAemEspot from '../SingleAemEspot/SingleAemEspot';
import SingleCmcEspot from '../SingleCmcEspot/SingleCmcEspot';
import style from './BrandExperience.module.scss';

const getStoreAttributeData = gql`
query storeAttributes {
  config {
    legacyBrandExperiencePages: genericType(name: "legacyBrandExperiencePages")
    hasAEMBrandExperience: genericType(name: "hasAEMBrandExperience")
  }
}
`;

const BrandExperience = ({ espotId, brand }) => {
  const { data: storeAttributeData, loading, error } = useQuery(getStoreAttributeData);
  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;
  const legacyBrandExperiencePages = storeAttributeData.config.legacyBrandExperiencePages.value;
  const hasAEMBrandExperience = storeAttributeData.config.hasAEMBrandExperience.value;
  let useAem = true;
  if (
    (legacyBrandExperiencePages.length > 0
      && legacyBrandExperiencePages.includes(espotId))
    || !hasAEMBrandExperience) {
    useAem = false;
  }
  return (
    <div className={style.wrapper}>
      {useAem ? <SingleAemEspot espotId={`${brand}-${espotId}`} /> : <SingleCmcEspot espotId={`${brand}-${espotId}`} />}
    </div>
  );
};

BrandExperience.propTypes = {
  brand: PropTypes.string.isRequired,
  espotId: PropTypes.string.isRequired,
};

export default BrandExperience;

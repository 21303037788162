import { Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import ClearAllButton from '../ClearAllButton/ClearAllButton';
import style from './SearchDefaultView.module.scss';

export default function SearchDefaultList({
  clearAllButtonHandler = () => { },
  dataType = '',
  hasClearAllButton = false,
  headingLabel = '',
  optionClickHandler = () => { },
  options = [],
  isList = false,
}) {
  const listData = options.map((item, index) => (
    <Button
      key={item}
      classList={isList ? style.suggestionListItem : 'suggestion-list-item'}
      index={index}
      onClick={optionClickHandler}
      type="button"
      value={item}
      variant={isList ? 'borderless' : 'tertiary-dark'}
    >
      {item}
    </Button>
  ));
  return (
    <div className={style.listContainer}>
      <div className={hasClearAllButton ? style.recentSearchesWrapper : ''}>
        <div className={hasClearAllButton ? style.recentSearchesHeaderWrapper : ''}>
          <h2 className="h3">{headingLabel}</h2>
          {hasClearAllButton && <ClearAllButton onClick={clearAllButtonHandler} />}
        </div>
        <div className={isList ? style.suggestionList : style.searchButtons} data-testid={`${dataType.replace(/\s/g, '-')}-button-group`} data-type={dataType}>
          {listData}
        </div>
      </div>
    </div>
  );
}

SearchDefaultList.propTypes = {
  clearAllButtonHandler: PropTypes.func,
  dataType: PropTypes.string,
  hasClearAllButton: PropTypes.bool,
  headingLabel: PropTypes.string,
  isList: PropTypes.bool,
  optionClickHandler: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
};

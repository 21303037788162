import { gql, useQuery } from '@apollo/client';
import { Breadcrumb } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import style from './BreadcrumbBar.module.scss';

const getBreadcrumbBarData = gql`
query Category($categoryId: String!) {
  category(categoryId: $categoryId) {
      breadcrumbTrailEntryView(exclude: ["61913732","61913735","61913834","62504252","62504254","63076125","63076129","63162686","63162700"]) {
        label
        url
      }
    }
  }
`;

function BreadcrumbBar({
  brand,
  categoryId,
}) {
  const {
    data, loading, error,
  } = useQuery(getBreadcrumbBarData, {
    variables: {
      categoryId,
    },
  });

  if (loading || error) return null;

  const {
    breadcrumbTrailEntryView,
  } = data.category;

  // On a "main" brand, leave out the L0.  Otherwise, if we're on a sub-brand, keep it in there!
  const breadCrumbArrayToUse = (brand === 'anf' || brand === 'hol') ? breadcrumbTrailEntryView.slice(1) : breadcrumbTrailEntryView;

  const getBreadCrumbs = () => breadCrumbArrayToUse.map(({ label, url }) => ({
    href: url,
    label,
  }));

  return (
    breadcrumbTrailEntryView.length > 0 && (
      <div className={`${style.breadcrumbBarContainer} scope-1892`}>
        <div className={style.mfeBreadcrumbBar}>
          <Breadcrumb
            crumbs={getBreadCrumbs()}
            labelText="Breadcrumb"
          />
        </div>
      </div>
    )
  );
}

BreadcrumbBar.propTypes = {
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
};

export default BreadcrumbBar;

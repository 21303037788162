import PropTypes from 'prop-types';
import React from 'react';
import MiniGridMarketingPortal from '../MiniGridMarketingPortal/MiniGridMarketingPortal';

const MiniGridExample = ({ categoryId }) => (
  <div>
    <h1 className="h3" style={{ padding: '20px' }}> Mini Grid Example</h1>
    <div className="wcs-mini-grid" data-category-id={categoryId} data-facet-ids="ads_f36005_ntk_cs,colorFamily, ads_f36501_ntk_cs" />
    <MiniGridMarketingPortal />
  </div>
);

MiniGridExample.propTypes = {
  categoryId: PropTypes.string.isRequired,
};

export default MiniGridExample;

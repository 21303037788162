import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import useTranslatedText from '../../hooks/useTranslatedText';
import TmntHtml from '../../tools/TmntHtml';
import FacetAccordions from './FacetsAccordionGroup';
import style from './FacetsLeftRail.module.scss';

function FacetsLeftRail({
  brand,
  facet = [],
  facetData = [],
  hasPriceFilterEnabled = false,
  highPrice = '',
  isCategoryPage = false,
  lowPrice = '',
  onCheckBoxChange = () => {},
  onPriceChange = () => {},
  popularSearches = [],
}) {
  const filterLabel = useTranslatedText('filters', { fallback: 'Filters' });
  const filterInstructionLabel = useTranslatedText('filterInstruction',
    { fallback: 'The following options filter your results as you apply each one.' });
  const breakpoints = useContext(BreakpointContext);
  const { large: isDesktop } = breakpoints;

  const facetLeftRailId = (isDesktop) ? 'facet-leftrail-wrapper-largescreen' : 'facet-leftrail-wrapper-smallscreen';

  return (
    <>
      <h2 className="screen-reader-text">
        <TmntHtml property={filterInstructionLabel.key || ''} value={filterInstructionLabel.value || ''} />
      </h2>
      <div aria-label={filterLabel.value} className={`${style.wrapper} Filters`} data-testid="filters-wrapper" role="region" tabIndex="-1">
        <div id={facetLeftRailId}>
          <FacetAccordions
            brand={brand}
            facet={facet}
            facetData={facetData}
            hasPriceFilterEnabled={hasPriceFilterEnabled}
            highPrice={highPrice}
            isCategoryPage={isCategoryPage}
            lowPrice={lowPrice}
            onCheckBoxChange={onCheckBoxChange}
            onPriceChange={onPriceChange}
            popularSearches={popularSearches}
          />
        </div>
      </div>
    </>
  );
}

FacetsLeftRail.propTypes = {
  // Required props
  brand: PropTypes.string.isRequired,
  facetData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  // Optional props
  isCategoryPage: PropTypes.bool,
  highPrice: PropTypes.string,
  lowPrice: PropTypes.string,
  hasPriceFilterEnabled: PropTypes.bool,
  onPriceChange: PropTypes.func,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onCheckBoxChange: PropTypes.func,
  popularSearches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default FacetsLeftRail;

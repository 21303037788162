import { Badge, Icon } from 'anf-core-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useContext, useState,
} from 'react';
import ProductGridContext from '../../context/ProductGridContext';
import BreakpointContext from '../../context/breakpoint';
import DigitalDataContext from '../../context/digitalData';
import useUpdateMySaves from '../../hooks/useUpdateMySaves';
import getPageType from '../../tools/getPageType';
import $window from '../../tools/window';
import { DD_DISABLE_SWATCH_HOVER, DD_HYPERLINK_DESC } from '../DigitalDataProvider';
import miniGridStyle from '../MiniGrid/MiniGrid.module.scss';
import { productType } from '../types';
import productCardClickBehaviorType, { CLICK_BEHAVIORS } from '../types/productCardClickBehaviorType';
import ProductCardBadges from './ProductCardBadges';
import ProductCardButtons from './ProductCardButtons';
import ProductCardDetails from './ProductCardDetails';
import ProductCardImages from './ProductCardImages';
import CardLink from './ProductCardLink';
import QuickviewButton from './ProductCardQuickviewButton';
import SaveButton from './ProductCardSaveButton';
import ShortDescriptor from './ProductCardShortDescriptor';
import Swatches from './ProductSwatches';
import style from './productCard.module.scss';
import useImagePreloading from './tools/useImagePreloading';

function ProductCard({
  categoryId = '',
  className = '',
  clickBehavior = CLICK_BEHAVIORS.productPage,
  deferImageLoad = true,
  isFirstProduct = false,
  isMiniGridSpotlightCard = false,
  isMiniGrid = false,
  product: {
    badges,
    collection,
    defaultSwatchSequence,
    departmentName,
    id: productId,
    imageSet,
    kic,
    memberPrice,
    name,
    price,
    productPageUrl,
    promoMessaging,
    shortDescriptors,
    socialProofMessage,
    swatchList,
  },
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isQuickviewOpen, setIsQuickviewOpen] = useState(false);
  const [hoveredSwatch, setHoveredSwatch] = useState(null);

  const faceoutImageType = imageSet.primaryFaceOutImage?.replace(/[A-Z\d\-_]/g, '');

  const [height, setHeight] = useState(0);
  const { large: isDesktop } = useContext(BreakpointContext);
  const { hadSwatchInteraction, imageHostName, brand } = useContext(ProductGridContext);

  const {
    [DD_DISABLE_SWATCH_HOVER]: digitalDataDisableSwatchHover,
    [DD_HYPERLINK_DESC]: digitalDataHyperlinkDescriptor,
  } = useContext(DigitalDataContext);

  const {
    productCardRef, setIsHoveredWithImagePreloading, imagesLoaded,
  } = useImagePreloading(swatchList, imageHostName, imageSet, setIsHovered, setHeight);

  const { handleSavesClick, isSaved } = useUpdateMySaves({
    productId,
    kic,
    collectionId: collection,
    swatchSequence: defaultSwatchSequence,
    longsku: '',
    colorCode: defaultSwatchSequence,
    price,
    brand,
    categoryId,
    name,
    isMiniGrid,
  });

  const handleSwatchHover = (newHoveredSwatch) => {
    const isDifferentSwatch = newHoveredSwatch?.id !== hoveredSwatch?.id;

    // Only update state if there is a new swatch
    // This is needed because object comparison (swatch vs swatch) is shallow
    if (!digitalDataDisableSwatchHover && isDifferentSwatch) {
      setHoveredSwatch(newHoveredSwatch);
    }

    if ($window.digitalData && newHoveredSwatch
      && isDifferentSwatch && !hadSwatchInteraction.current) {
      const dataText = 'swatch_hover';
      const eventType = 'click';

      $window.digitalData.trigger(`${getPageType()}_${dataText}_${eventType}`, {
        event_type: eventType,
        data_text: dataText,
        data_action: 'hover',
      });
      hadSwatchInteraction.current = true;
    }
  };

  const selectedSwatchName = swatchList?.find((swatch) => swatch.product.id === productId)?.name ?? '';

  const handleQuickviewClick = (event) => {
    if (socialProofMessage) {
      $window.digitalData.set('product.afSource', 'social proofing');
    } else {
      // Unset data in case it was set previously
      $window.digitalData.set('product.afSource', undefined);
    }

    if (isMiniGrid && categoryId) {
      $window.digitalData.set('product.productViewMethod', `mini grid - ${categoryId}`);
      $window.digitalData.set('page.pageViewMethod', 'modal - quick view');

      const analyticsQVEvent = new CustomEvent('analytics.quick_view');
      $window.digitalData.trigger(analyticsQVEvent);
    }

    const quickviewEvent = new CustomEvent('quickview:openModal', {
      detail: {
        productId,
        collectionId: collection,
        seq: defaultSwatchSequence,
        productName: name,
        imageId: imageSet.primaryFaceOutImage,
        productBrand: brand,
        faceout: faceoutImageType,
        returnFocus: event.currentTarget,
        origin: isMiniGrid ? 'mini grid' : '',
        categoryId: categoryId ?? '',
        afsource: socialProofMessage ? 'social proofing' : '',
      },
    });
    /**
     * There's a possibility that something goes wrong and the QV doesn't open,
     * which will mess up our state.
     * This is the best we can do since we're not getting any acknowledgement
     * as to whether or not the QV opened in response to our event.
     */
    setIsQuickviewOpen(true);
    try {
      $window.dispatchEvent(quickviewEvent);
    } catch (error) {
      // TODO: Log this?
      setIsQuickviewOpen(false);
    }
  };

  const handleCardHover = (value) => {
    // Disable hover functionality for mobile
    if (isDesktop) {
      setIsHoveredWithImagePreloading(value);
    }
  };

  const handleCardFocus = () => {
    handleCardHover(true);
    setIsQuickviewOpen(false);
  };

  const handleCardBlur = (e) => {
    /**
     * Consider the card still focused if it or any of its focusable
     * descendants are next to be focused. This is particularly important so
     * that all swatches remain existent/focusable as you tab through them
     */
    handleCardHover(productCardRef.current.contains(e.relatedTarget));
  };

  const handleProductCardClick = (event) => {
    if (clickBehavior === CLICK_BEHAVIORS.quickview) {
      event.preventDefault();
      handleQuickviewClick(event);
    } else if (clickBehavior === CLICK_BEHAVIORS.productPage) {
      if (isMiniGrid && categoryId) {
        $window.digitalData.set('product.productViewMethod', `mini grid - ${categoryId}`);
        $window.digitalData.set('page.pageViewMethod', 'page load');

        const analyticsPDEvent = new CustomEvent('analytics.product_detail');
        $window.digitalData.trigger(analyticsPDEvent);
      }
    }
  };

  const showHyperlinkShortDescriptors = !!digitalDataHyperlinkDescriptor;
  const showProductCardButtons = (!isDesktop || isHovered || isQuickviewOpen);

  return (
    <li
      ref={productCardRef}
      className={classNames(style.productCard, className, {
        [style.hover]: isHovered,
        [miniGridStyle.spotlightCard]: isMiniGridSpotlightCard,
        [style.topBadge]: socialProofMessage,
      })}
      data-aui="product-card"
      data-intlkic={kic}
      onBlur={handleCardBlur}
      onFocus={handleCardFocus}
      onMouseEnter={() => handleCardHover(true)}
      onMouseLeave={() => handleCardHover(false)}
      style={{ maxHeight: isHovered ? height : null }}
    >
      <div className={`${style.template} product-template`}>
        {socialProofMessage && !(brand === 'hol' && !isDesktop) && (
          <div className={`${style.socialProofingBadgeContainer}`}>
            <Badge>
              <Icon icon="flame-filled" />
              {socialProofMessage}
            </Badge>
          </div>
        )}
        <div className={style['product-image-section']}>
          <ProductCardImages
            forceLifestyleImage={isMiniGridSpotlightCard}
            hover={isHovered}
            hoveredSwatch={hoveredSwatch}
            imageSet={imageSet}
            isFirstProduct={isFirstProduct}
            onClick={handleProductCardClick}
            productName={name}
            productPageUrl={productPageUrl}
            selectedSwatchName={selectedSwatchName}
            showPlaceholder={deferImageLoad && !imagesLoaded}
          />

          <SaveButton
            onClick={handleSavesClick}
            saved={isSaved}
          />
          {showProductCardButtons && (
            <ProductCardButtons>
              <QuickviewButton onClick={handleQuickviewClick} />
            </ProductCardButtons>
          )}
        </div>
        <div className={`${style.content} product-content`}>
          {swatchList?.length > 0 && (
            <Swatches
              handleSwatchHover={handleSwatchHover}
              limitSwatches={!isHovered}
              productId={productId}
              productName={name}
              productPageUrl={productPageUrl}
              showPlaceholder={deferImageLoad && !imagesLoaded}
              swatchList={swatchList}
            />
          )}
          <div className={style['product-details-wrapper']}>
            <CardLink onClick={handleProductCardClick} url={productPageUrl}>
              <ProductCardBadges
                badges={badges ?? []}
              />
              <ProductCardDetails
                memberPrice={memberPrice}
                name={name}
                price={price}
                promoMessaging={promoMessaging}
              />
              {(!showHyperlinkShortDescriptors && shortDescriptors?.length > 0) && (
                <ShortDescriptor
                  brand={brand}
                  departmentName={departmentName}
                  shortDescriptors={shortDescriptors}
                />
              )}
            </CardLink>
            {(showHyperlinkShortDescriptors && shortDescriptors?.length > 0) && (
              <ShortDescriptor
                brand={brand}
                departmentName={departmentName}
                shortDescriptors={shortDescriptors}
              />
            )}
          </div>
        </div>
      </div>
    </li>
  );
}

ProductCard.propTypes = {
  categoryId: PropTypes.string,
  className: PropTypes.string,
  clickBehavior: productCardClickBehaviorType,
  deferImageLoad: PropTypes.bool,
  isFirstProduct: PropTypes.bool,
  isMiniGridSpotlightCard: PropTypes.bool,
  isMiniGrid: PropTypes.bool,
  product: productType.isRequired,
};

export default ProductCard;

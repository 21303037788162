import Toaster from 'anf-core-react/components/Toaster';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import SmallScreenNavFooter from '../SmallScreenNavFooter/SmallScreenNavFooter';
import style from '../SmallScreenNavFooter/SmallScreenNavFooter.module.scss';

const SmallScreenNav = ({
  defaultCountry = '',
  handleNavToggle,
  navOpen,
}) => {
  const closeMenu = useTranslatedText('closeMenu', { fallback: 'Close' });

  return (
    <>
      <Toaster
        closeButtonLabel={closeMenu.value}
        direction="from-left"
        id="catalogNavToaster"
        isFullBleed
        isOpen={navOpen}
        onClose={handleNavToggle}
      >
        <div className={style.toasterContent}>
          <nav>
            <ul>
              <li>Links</li>
              <li>Links</li>
              <li>Links</li>
              <li>Links</li>
              <li>Links</li>
              <li>Links</li>
            </ul>
          </nav>
          <div className={style.footerToaster}>
            <SmallScreenNavFooter defaultCountry={defaultCountry} />
          </div>
        </div>
      </Toaster>
    </>
  );
};

SmallScreenNav.propTypes = {
  defaultCountry: PropTypes.string,
  handleNavToggle: PropTypes.func.isRequired,
  navOpen: PropTypes.bool.isRequired,
};

export default SmallScreenNav;

import { GenericRecommendationsContextProvider } from '@xp-utilities/web';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useInterval, useTimeout } from 'react-use';
import BreakpointProvider from '../BreakpointProvider';
import DigitalDataProvider, {
  DD_DISABLE_SWATCH_HOVER,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
} from '../DigitalDataProvider';
import ProductGridContextProvider from '../ProductGridContextProvider';
import CategoryRecommendations from './CategoryRecommendations';

const ROOT_CLASSNAME = 'mfe-personalized-products-recs';
const PLACEMENT_ID_ATTRIBUTE = 'data-placement-id';
const ROOT_SELECTOR = `.${ROOT_CLASSNAME}[${PLACEMENT_ID_ATTRIBUTE}]`;
const TOTAL_MS_FOR_DOM_QUERIES = 5000;
const MS_BETWEEN_DOM_QUERIES = 500;
const getTargetNodes = () => Array.from(document.querySelectorAll(ROOT_SELECTOR))
  .reduce((nodes, element) => {
    // Make sure the placement ID attribute is not empty
    if (element.getAttribute(PLACEMENT_ID_ATTRIBUTE)) {
      nodes.push(element);
    }

    return nodes;
  }, []);

function CategoryRecommendationsPortal() {
  const [targetNodes, setTargetNodes] = useState([]);
  const [isFinishedTimeout] = useTimeout(TOTAL_MS_FOR_DOM_QUERIES);
  const updateTargetNodes = useCallback(() => {
    const newTargetNodes = getTargetNodes();

    // Update only if the nodes are different
    if (
      newTargetNodes.length !== targetNodes.length
      || !newTargetNodes.every((node) => targetNodes.includes(node))
    ) {
      setTargetNodes(newTargetNodes);
    }
  }, [setTargetNodes, targetNodes]);

  // Render to target nodes immediately
  useEffect(() => {
    updateTargetNodes();
  }, [updateTargetNodes]);

  // Check for target node updates for some time
  useInterval(() => {
    updateTargetNodes();
  }, isFinishedTimeout() ? null : MS_BETWEEN_DOM_QUERIES);

  if (targetNodes.length === 0) {
    return null;
  }

  return (
    <BreakpointProvider>
      <GenericRecommendationsContextProvider>
        <ProductGridContextProvider>
          <DigitalDataProvider
            keys={[
              DD_DISABLE_SWATCH_HOVER,
              DD_HYPERLINK_DESC,
              DD_MODEL_IMAGERY_TEST,
            ]}
          >
            {/* Wrapper fragment used to satisfy DigitalDataProvider prop-types; see https://github.com/facebook/prop-types/issues/167 */}
            <>
              {targetNodes.map((targetNode) => (
                createPortal(
                  <CategoryRecommendations
                    placementIds={targetNode.getAttribute(PLACEMENT_ID_ATTRIBUTE)}
                  />,
                  targetNode,
                )
              ))}
            </>
          </DigitalDataProvider>
        </ProductGridContextProvider>
      </GenericRecommendationsContextProvider>
    </BreakpointProvider>
  );
}

export default CategoryRecommendationsPortal;

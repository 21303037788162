import { useQuery } from '@apollo/client';
import { CountrySelector, FlagBlock, Link as LinkButton } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import { COUNTRY_DATA } from '../../queries';
import getUserCountry from '../../tools/getUserCountry';
import { ERROR_MESSAGE } from '../Messages/Messages';
import style from './UserPreferences.module.scss';

function UserPreferences({
  defaultCountry = '',
}) {
  const changeLabel = useTranslatedText('change', { fallback: 'Change' });

  const {
    data: countriesData, loading, error,
  } = useQuery(COUNTRY_DATA);

  if (error) return ERROR_MESSAGE;
  if (loading) return null;

  const userCountry = getUserCountry(countriesData, defaultCountry);

  const handleCountryChangeClick = (event) => {
    event.preventDefault();
    if (document && document.querySelector('.js-open-modal[data-modal="ds-modal-upref-menu__selection"]')) {
      document.querySelector('.js-open-modal[data-modal="ds-modal-upref-menu__selection"]').click();
    }
  };

  return (
    <div className={style.countrySelector}>
      <CountrySelector
        flagBlock={(
          <FlagBlock countryCode={userCountry.countryCode} countryName={userCountry.name} />
        )}
        // Update to LinkButton component which should open the toaster from MFE when it is ready
        link={<LinkButton onClick={handleCountryChangeClick}>{ changeLabel.value }</LinkButton>}
      />

      <button className={`button ds-override js-open-modal ${style.hideButtonForCrs}`} data-modal="ds-modal-upref-menu__selection">Change</button>
    </div>
  );
}

export default UserPreferences;

UserPreferences.propTypes = {
  defaultCountry: PropTypes.string,
};

import {
  any,
  arrayOf,
  bool,
  func,
  number,
  objectOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, {
  useEffect,
  useRef,
} from 'react';
import { useUpdateEffect } from 'react-use';
import useTranslatedText from '../../hooks/useTranslatedText';
import { AUTOSUGGEST_THRESHOLD } from '../../tools/constants';
import RefineButton from '../Refine/RefineButton';
import SearchInputField from '../SearchInputField/SearchInputField';
import SuggestedFilters from '../SuggestedFilters/SuggestedFilters';
import style from './EditableSearchHeader.module.scss';
import SuggestionList from './SuggestionList';
import useAutoSuggest from './hooks/useAutoSuggest';
import useSearchBarState from './hooks/useSearchBarState';

export default function EditableSearchHeader({
  brand,
  debounceDelay = 1000,
  facet = [],
  facetData = [],
  filter = '',
  hasPriceFilterEnabled = false,
  highPrice = '',
  isDesktop = false,
  isFacetSelected = false,
  leftRailMobileFlag = false,
  lowPrice = '',
  onCheckBoxChange = () => {},
  onClearAllBtnClick = () => {},
  onPriceChange = () => {},
  onSearchTermChange = () => {},
  onSortChange = () => {},
  resultsCount = null,
  searchTerm = '',
  suggestionObject = {
    words: [],
    phrases: [],
  },
  selectedSort = '',
  showStickyBar = false,
  sortData = {
    sortOptions: [],
  },
}) {
  const wrapper = useRef(null);
  const searchInputField = useRef(null);
  const [
    addSuggestion,
    blurHandler,
    clearSearchterm,
    clickedButtons,
    editableSearchTerm,
    focusHandler,
    focusOnSearchInput,
    isFocused,
    removeSuggestion,
    setEditableSearchTerm,
    submitSearchTerm,
  ] = useSearchBarState(onSearchTermChange, searchInputField, searchTerm, wrapper);
  const {
    displayedData, keyDownHandler,
  } = useAutoSuggest(
    blurHandler,
    editableSearchTerm,
  );

  const showSuggestions = ((searchTerm.split(' ').length < 4 && suggestionObject?.rankedWords?.words?.length > 0)
  || clickedButtons?.size > 0);
  const showAutoSuggest = (
    !showStickyBar && isFocused && editableSearchTerm.length >= AUTOSUGGEST_THRESHOLD);

  useUpdateEffect(() => {
    if (showStickyBar) {
      wrapper.current.parentElement.classList.add(style.sticky);
    } else {
      wrapper.current.parentElement.classList.remove(style.sticky);
    }
  },
  [showStickyBar]);

  // Update the editable search term when the search term changes
  // from outside this component (e.g. when the user clicks on a search suggestion)
  useEffect(() => {
    setEditableSearchTerm(searchTerm);
  }, [searchTerm, setEditableSearchTerm]);

  const clearLabel = useTranslatedText('autoSuggestClearButtonLabel', { fallback: 'Clear' });
  const placeholder = useTranslatedText('autoSuggestDesktopPlaceholder', { fallback: 'Search' });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      ref={wrapper}
      className={`scope-1892 ${style.editableSearchHeader} ${showStickyBar ? style.resizeForSticky : null} ${showSuggestions ? style.resizeForSuggestions : null}`}
      onBlur={blurHandler}
      onFocus={focusHandler}
      onKeyDown={keyDownHandler}
      onSubmit={submitSearchTerm}
    >
      <fieldset>
        <p id="editable-search-description">
          {`Search form automatically submits ${debounceDelay / 1000} seconds after input.`}
        </p>
        <SearchInputField
          ref={searchInputField}
          autoComplete="off"
          blurHandler={blurHandler}
          buttonLabelText={placeholder.value}
          clearButtonIcon="close"
          clearButtonLabelText={clearLabel.value}
          clearButtonOnClick={clearSearchterm}
          describedBy="editable-search-description"
          id="editable-search-input-field"
          isClearButton={editableSearchTerm.length > 0}
          isFocused={isFocused}
          labelText={placeholder.value}
          name="searchTerm"
          onChange={(event) => {
            setEditableSearchTerm(event.currentTarget.value);
          }}
          placeholder={placeholder.value}
          searchButtonOnClick={submitSearchTerm}
          value={editableSearchTerm}
        />
        {showStickyBar && !isDesktop ? (
          <RefineButton
            brand={brand}
            facet={facet}
            facetData={facetData}
            filter={filter}
            hasIconOnly
            hasPriceFilterEnabled={hasPriceFilterEnabled}
            highPrice={highPrice}
            isFacetSelected={isFacetSelected}
            leftRailMobileFlag={leftRailMobileFlag}
            lowPrice={lowPrice}
            onCheckBoxChange={onCheckBoxChange}
            onClearAllBtnClick={onClearAllBtnClick}
            onPriceChange={onPriceChange}
            onSortChange={onSortChange}
            resultsCount={resultsCount}
            selectedSort={selectedSort}
            sortData={sortData}
          />
        ) : null}
        { showAutoSuggest
          ? (
            <SuggestionList
              blurHandler={blurHandler}
              focusOnSearchInput={focusOnSearchInput}
              isFormFocused={isFocused}
              onSearchTermChange={onSearchTermChange}
              setSearchTerm={setEditableSearchTerm}
              suggestions={displayedData}
            />
          )
          : null }
      </fieldset>
      { showSuggestions
        ? (
          <fieldset>
            <SuggestedFilters
              addSuggestion={addSuggestion}
              clickedSuggestions={clickedButtons}
              removeSuggestion={removeSuggestion}
              searchTerm={searchTerm}
              suggestionObject={suggestionObject}
            />
          </fieldset>
        )
        : null}
    </form>
  );
}

EditableSearchHeader.propTypes = {
  brand: string.isRequired,
  debounceDelay: number,
  facet: oneOfType([
    arrayOf(string),
    string,
  ]),
  facetData: arrayOf(objectOf(any)),
  filter: string,
  isDesktop: bool,
  isFacetSelected: bool,
  showStickyBar: bool,
  leftRailMobileFlag: bool,
  onCheckBoxChange: func,
  onClearAllBtnClick: func,
  onSortChange: func,
  onPriceChange: func,
  highPrice: string,
  hasPriceFilterEnabled: bool,
  lowPrice: string,
  onSearchTermChange: func,
  resultsCount: number,
  searchTerm: string,
  suggestionObject: shape({
    rankedWords: shape({
      words: arrayOf(string),
      wordObjects: arrayOf(shape({
        word: string,
        count: number,
      })),
    }),
    rankedPhrases: shape({
      phrases: arrayOf(string),
      phraseObjects: arrayOf(shape({
        phrase: string,
        count: number,
      })),
    }),
  }),
  selectedSort: string,
  sortData: shape({
    sortOptions: arrayOf(shape({
      id: string,
      value: string,
    })),
  }),
};

import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import getMySavesFromLocalStorage from '../tools/getMySavesFromLocalStorage';
import $window from '../tools/window';

const getSavesListQuery = gql`
query SavesList {
  savesList {
    productId
    kic
    sku
  }
}
`;

export const addToListMutation = gql`
mutation addToMySaves(
  $productId: String!, 
  $kic: String!, 
  $collectionId: String, 
  $swatchSequence: String, 
  $longSku: String, 
  $colorCode: String
) {
  addToMySaves(
    productId: $productId, 
    kic: $kic, 
    collectionId: $collectionId, 
    swatchSequence: $swatchSequence, 
    longSku: $longSku, 
    colorCode: $colorCode
  ) {
    success
    isFirstTimeSave
    responseData {
      productId
      kic
      sku
    }
    errors {
      message {
          key
          value
      }
      status
    }
  }
}
`;

export const removeFromListMutation = gql`
mutation removeFromMySaves(
  $productId: String!, 
  $kic: String!, 
  $collectionId: String, 
  $swatchSequence: String, 
  $longSku: String, 
  $colorCode: String
) {
  removeFromMySaves(
    productId: $productId, 
    kic: $kic, 
    collectionId: $collectionId, 
    swatchSequence: $swatchSequence, 
    longSku: $longSku, 
    colorCode: $colorCode
  ) {
    success
    responseData {
      productId
      kic
      sku
    }
    errors {
      message {
          key
          value
      }
      status
    }
  }
}
`;

const getStoreAttributeData = gql`
  query StoreAttribute {
    storeAttribute {
      hasMfeMySavesEnabled: booleanStoreAttribute(name: "HasMfeMySavesEnabled") {
        id
        value
      }
    }
  }
`;

const getNewTimeStamp = () => new Date().getTime() + 60000;

const getDefaultMySaves = () => ({
  list: {},
  savesCount: 0,
  timestamp: -1,
  timeStamp: getNewTimeStamp(),
});

function getSettings(addToList, removeFromList) {
  return {
    ADDING: {
      mutationToMake: addToList,
      listDataType: 'addToMySaves',
      analyticsVariation: 'addTo',
    },
    REMOVING: {
      mutationToMake: removeFromList,
      listDataType: 'removeFromMySaves',
      analyticsVariation: 'removeFrom',
    },
  };
}

const formatList = (rawList) => rawList.reduce((acc, curr) => {
  acc[curr.productId] = {
    productId: curr.productId,
    kic: curr.kic,
    sku: curr.sku,
  }; return acc;
}, {});

const useUpdateMySaves = ({
  productId,
  kic,
  collectionId,
  swatchSequence,
  longSku,
  colorCode,
  price,
  brand,
  categoryId,
  name,
  isMiniGrid,
}) => {
  const [savesList, setSavesList] = useState({});
  const [isCurrentProductSaved, setIsCurrentProductSaved] = useState(false);

  const [savesListLocalStorage, setSavesListLocalStorage] = useLocalStorage('savesList', getDefaultMySaves());

  const isSaved = typeof savesList[productId] !== 'undefined';

  const { data: storeAttributeData } = useQuery(getStoreAttributeData);
  const hasMfeMySavesEnabled = storeAttributeData?.storeAttribute?.hasMfeMySavesEnabled?.value;

  const [getList] = useLazyQuery(getSavesListQuery, {});
  const [addToList] = useMutation(addToListMutation);
  const [removeFromList] = useMutation(removeFromListMutation);
  const settings = useMemo(() => getSettings(addToList, removeFromList),
    [addToList, removeFromList]);

  useEffect(() => {
    if (hasMfeMySavesEnabled) {
      getList().then(({ data }) => {
        const formattedList = formatList(data.savesList);
        setSavesList(formattedList);
        setSavesListLocalStorage(formattedList);
      }).catch(() => {
        setSavesList({});
      });
    } else {
      const mySavesObject = getMySavesFromLocalStorage();
      const savedStatus = !!(mySavesObject?.list?.[productId]);
      setIsCurrentProductSaved(savedStatus);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMfeMySavesEnabled, productId]);

  const sendAnalyticsData = (analyticTriggerVariation) => {
    $window?.dispatchEvent(
      new CustomEvent(`analytics.${analyticTriggerVariation}WishListSuccess`, { detail: { collectionId, longSku, colorCode } }),
    );

    if ($window.digitalData) {
      const eventData = isMiniGrid ? {
        brand: brand ?? 'null',
        category_id: categoryId ?? 'null',
        collection_id: collectionId ?? 'null',
        key_item_color: kic ?? 'null',
        long_sku: 'null',
        price_flag: price.priceFlag ?? 'null',
        price_list_local: price.originalPrice ?? 'null',
        price_list_usd: 'null',
        price_offer_local: price.discountPrice ?? 'null',
        price_offer_usd: 'null',
        product_id: productId ?? 'null',
        product_name: name ?? 'null',
        short_sku: 'null',
      } : {
        product_id: productId ?? 'null',
        collection_id: collectionId ?? 'null',
        price_flag: price.priceFlag ?? 'null',
        key_item_color: kic ?? 'null',
        price_list_local: price.originalPrice ?? 'null',
        price_offer_local: price.discountPrice ?? 'null',
        short_sku: 'null',
        long_sku: longSku ?? 'null',
        product_name: name ?? 'null',
        price_list_usd: 'null',
        price_offer_usd: 'null',
      };

      $window.digitalData.trigger(analyticTriggerVariation === 'addTo' ? 'analytics.save_for_later' : 'analytics.remove_from_saves', eventData);
    }
  };

  const handleSavesClick = async () => {
    const setting = isSaved ? settings.REMOVING : settings.ADDING;

    setting.mutationToMake({
      variables: {
        productId, kic, collectionId, swatchSequence, longSku, colorCode,
      },
      onCompleted: (data) => {
        const formattedList = formatList(data[setting.listDataType].responseData);
        setSavesList(formattedList);

        if (setting.listDataType === 'addToMySaves') {
          $window.dispatchEvent(new CustomEvent('crs:mySaveSuccess',
            { detail: { isFirstTimeSave: data[setting.listDataType].isFirstTimeSave } }));
        }

        sendAnalyticsData(setting.analyticsVariation);

        setSavesListLocalStorage({
          ...savesListLocalStorage,
          list: formattedList,
          savesCount: data[setting.listDataType].responseData.length,
          timeStamp: getNewTimeStamp(),
        });
      },
    });
  };

  const handleSavesClickLegacy = () => {
    const eventType = isCurrentProductSaved ? `mfe:productRemoveSave${isMiniGrid ? 'MiniGrid' : ''}` : `mfe:productAddSave${isMiniGrid ? 'MiniGrid' : ''}`;
    const saveEvent = new CustomEvent(eventType, {
      detail: {
        productId,
        kic,
        collectionId,
        swatchSequence,
        longsku: '',
        colorCode,
      },
    });
    $window.dispatchEvent(saveEvent);

    if (isMiniGrid && $window.digitalData) {
      const eventData = {
        brand: brand ?? 'null',
        category_id: categoryId ?? 'null',
        collection_id: collectionId ?? 'null',
        key_item_color: kic ?? 'null',
        long_sku: 'null',
        price_flag: price.priceFlag ?? 'null',
        price_list_local: price.originalPrice ?? 'null',
        price_list_usd: 'null',
        price_offer_local: price.discountPrice ?? 'null',
        price_offer_usd: 'null',
        product_id: productId ?? 'null',
        product_name: name ?? 'null',
        short_sku: 'null',
      };
      $window.digitalData.trigger(isCurrentProductSaved ? 'analytics.remove_from_saves' : 'analytics.save_for_later', eventData);
    }
    setIsCurrentProductSaved(!isCurrentProductSaved);
  };

  return hasMfeMySavesEnabled ? {
    isSaved,
    handleSavesClick,
  } : {
    isSaved: isCurrentProductSaved,
    handleSavesClick: handleSavesClickLegacy,
  };
};

export default useUpdateMySaves;

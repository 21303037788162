import DigitalDataProvider from './DigitalDataProvider';
import DigitalDataTestProvider from './DigitalDataTestProvider';

// TEST FLAGS
const DD_DISABLE_SWATCH_HOVER = 'test.disableSwatchHover';
const DD_HYPERLINK_DESC = 'test.mfeHyperlinkDescriptor';
const DD_MODEL_IMAGERY_TEST = 'test.modelImageryTest';
const DD_PAGINATION_TEST = 'test.paginationVsLoadMoreTest';
const DD_SEARCH_INSTRUCTIONS_TEST = 'test.searchInstructions';
const DD_SHOW_ANF_NAVIGATION_L3S_TEST = 'test.showAnfNavigationL3s';

// FEATURE FLAGS
const DD_EDITABLE_SEARCH_FLAG = 'flag.editable-search';
const DD_EDITABLE_SEARCH_INPUT_DEBOUNCE = 'flag.editable-search-debounce-timeout';
const DD_ENHANCED_STICKY_BAR = 'flag.enhanced-sticky-bar';
const DD_GENDER_FLYOUT_TABS_FLAG = 'flag.has-dept-flyout-tabs';
const DD_SEARCH_FLYOUT_IMAGE_SUGGESTIONS = 'flag.search-flyout-image-suggestions';
const DD_SEARCH_HELP_TOPICS = 'flag.search-flyout-help-topics';
const DD_PRICE_FILTER_FLAG = 'flag.search-price-filter';
const DD_SEARCH_INPUT_QUERY_DELAY = 'flag.search-debounce-timeout';
const DD_SIMILAR_PRODUCTS_PDP_FLAG = 'flag.search-shop-similar-on-pdp';
const DD_USE_ELASTIC_SEARCH_SUGGESTION_API = 'flag.use-elastic-search-suggestions-api';
const DD_SEARCH_INSTRUCTIONS = 'flag.search-instructions';
const DD_TIK_TOK_FOOTER_ICON_FLAG = 'flag.tik-tok-footer-icon';
const DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS = 'flag.no-results-department-suggestions';

export {
  DigitalDataTestProvider,
  DD_DISABLE_SWATCH_HOVER,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
  DD_PAGINATION_TEST,
  DD_EDITABLE_SEARCH_FLAG,
  DD_EDITABLE_SEARCH_INPUT_DEBOUNCE,
  DD_ENHANCED_STICKY_BAR,
  DD_GENDER_FLYOUT_TABS_FLAG,
  DD_SEARCH_FLYOUT_IMAGE_SUGGESTIONS,
  DD_SEARCH_HELP_TOPICS,
  DD_SEARCH_INPUT_QUERY_DELAY,
  DD_SIMILAR_PRODUCTS_PDP_FLAG,
  DD_USE_ELASTIC_SEARCH_SUGGESTION_API,
  DD_PRICE_FILTER_FLAG,
  DD_SEARCH_INSTRUCTIONS,
  DD_SEARCH_INSTRUCTIONS_TEST,
  DD_TIK_TOK_FOOTER_ICON_FLAG,
  DD_SHOW_ANF_NAVIGATION_L3S_TEST,
  DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS,
};

export default DigitalDataProvider;

import { gql, useQuery } from '@apollo/client';
import { RecommendationsContext } from '@xp-utilities/web';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import parsePlacementIds from '../../tools/parsePlacementIds';
import CategoryRecommendationsTabs from './CategoryRecommendationsTabs';

const CATEGORY_RECOMMENDATIONS_QUERY = gql`
  query CATEGORY_RECOMMENDATIONS_QUERY($placements: [String]!, $hasUserConsent: Boolean!) {
    categoryRecommendations(placements: $placements, hasUserConsent: $hasUserConsent) {
      placements {
        id
        title
        products {
          badges {
            text
            theme
          }
          collection
          defaultSwatchSequence
          departmentName
          id
          imageSet {
            lifestyleImage
            modelImage
            primaryFaceOutImage
            primaryHoverImage
            prodImage
          }
          kic
          memberPriceObject {
            description
            discountPrice
            discountText
            originalPrice
            variant
          }
          name
          partNumber
          priceObject {
            description
            discountPrice
            discountText
            originalPrice
            variant
          }
          productPageUrl
          promoMessaging {
            message
            type
          }
          shortDescriptors
        }
      }
    }
  }
`;

function CategoryRecommendations({ placementIds }) {
  const breakpoints = useContext(BreakpointContext);

  const {
    variables: { hasUserConsent },
  } = useContext(RecommendationsContext);
  const placements = parsePlacementIds(placementIds, breakpoints);
  const {
    data,
  } = useQuery(CATEGORY_RECOMMENDATIONS_QUERY, {
    // Execute query only if variables are properly formed
    skip: isEmpty(placements),
    variables: {
      hasUserConsent,
      placements,
    },
  });

  const placementData = data?.categoryRecommendations?.placements
    ?.map((item) => ({ ...item, id: String(item.id) }));

  if (!placementData) {
    return null;
  }

  return (
    <>
      <CategoryRecommendationsTabs placementData={placementData} />
    </>
  );
}

export default CategoryRecommendations;

CategoryRecommendations.propTypes = {
  placementIds: PropTypes.string.isRequired,
};

import { useQuery } from '@apollo/client';
import useDigitalData from '../../../hooks/useDigitalData';
import {
  DD_EDITABLE_SEARCH_FLAG,
  DD_EDITABLE_SEARCH_INPUT_DEBOUNCE,
  DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS,
  DD_PRICE_FILTER_FLAG,
} from '../../DigitalDataProvider';
import { STORE_ATTRIBUTES } from '../queries';

const useFeatureFlags = () => {
  const { data: storeAttributeData } = useQuery(STORE_ATTRIBUTES);

  const {
    [DD_EDITABLE_SEARCH_FLAG]: editableSearchFlag,
    [DD_EDITABLE_SEARCH_INPUT_DEBOUNCE]: debounceDelay,
    [DD_PRICE_FILTER_FLAG]: priceFilterFlag,
    [DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS]: departmentSuggestionsFlag,
  } = useDigitalData([
    DD_EDITABLE_SEARCH_FLAG,
    DD_EDITABLE_SEARCH_INPUT_DEBOUNCE,
    DD_PRICE_FILTER_FLAG,
    DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS,
  ]);

  const facetsLeftRailFlag = storeAttributeData?.config?.facetsLeftRailFlag?.value ?? false;
  const leftRailMobileFlag = storeAttributeData?.config?.leftRailMobileFlag?.value ?? false;
  const hasShopMyStoreEnabled = storeAttributeData?.config?.hasShopMyStoreEnabled?.value ?? false;

  return {
    editableSearchFlag,
    debounceDelay,
    priceFilterFlag,
    departmentSuggestionsFlag,
    facetsLeftRailFlag,
    leftRailMobileFlag,
    hasShopMyStoreEnabled,
  };
};

export default useFeatureFlags;

import {
  Accordion,
  ErrorMessage,
  InputField,
  Link as LinkAsButton,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { PRICE_MIN_MAX_MESSAGE, PRICE_NEGATIVE_NUMBER_MESSAGE } from '../../tools/constants';
import getPriceFilterLabel from '../../tools/getPriceFilterLabel';
import numericValue from '../../tools/numericValue';
import style from './PriceAccordion.module.scss';

//  This Price Filter is a POC and only for US.
//  Once it is proven out we need to update all text strings to be TMNTs
//  and dynamically display currnecy symbols based on the store.
function PriceAccordion({
  brand,
  lowPrice: intlLowPrice,
  highPrice: intlHighPrice,
  onPriceChange,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [lowPrice, setLowPrice] = useState(intlLowPrice);
  const [highPrice, setHighPrice] = useState(intlHighPrice);
  const [isInvalid, setIsInvalid] = useState({ status: false, message: '' });

  useUpdateEffect(() => {
    setLowPrice(intlLowPrice);
    setHighPrice(intlHighPrice);
  }, [intlLowPrice, intlHighPrice]);

  const isNegativePrice = (low, high) => low < 0 || high < 0;
  const isLowGreaterOrEqualToHigh = (low, high) => high !== 0 && low >= high;
  const isEitherPriceZero = (low, high) => low === 0 || high === 0;

  const applyButtonHandler = (event) => {
    event.preventDefault();
    const low = Number(lowPrice);
    const high = Number(highPrice);

    if (isNegativePrice(low, high)) {
      setIsInvalid({ status: true, message: PRICE_NEGATIVE_NUMBER_MESSAGE });
    } else if (isEitherPriceZero(low, high)) {
      setIsInvalid({ status: false, message: '' });
      onPriceChange({
        lowPrice: lowPrice === '0' ? '' : lowPrice,
        highPrice: highPrice === '0' ? '' : highPrice,
      });
    } else if (isLowGreaterOrEqualToHigh(low, high)) {
      setIsInvalid({ status: true, message: PRICE_MIN_MAX_MESSAGE });
    } else {
      setIsInvalid({ status: false, message: '' });
      onPriceChange({ lowPrice, highPrice });
    }
  };

  const handleArrowUp = (event) => {
    if (event.target.id === 'low-price') {
      setLowPrice((prevLowPrice) => (Number(prevLowPrice) + 1).toString());
    } else if (event.target.id === 'high-price') {
      setHighPrice((prevHighPrice) => (Number(prevHighPrice) + 1).toString());
    }
  };

  const handleArrowDown = (event) => {
    const { id } = event.target;
    const setPrice = id === 'low-price' ? setLowPrice : setHighPrice;

    setPrice((prevPrice) => {
      if (Number(prevPrice) > 0) {
        return (Number(prevPrice) - 1).toString();
      }
      setIsInvalid({ status: true, message: PRICE_NEGATIVE_NUMBER_MESSAGE });
      return prevPrice;
    });
  };

  const onInputFieldKeyDown = (event) => {
    if (event.key === 'Enter') {
      applyButtonHandler(event);
    } else if (event.key === 'ArrowUp') {
      handleArrowUp(event);
    } else if (event.key === 'ArrowDown') {
      handleArrowDown(event);
    }
  };

  const clearButtonHandler = () => {
    setLowPrice('');
    setHighPrice('');
    setIsInvalid({ status: false, message: '' });
    onPriceChange({ lowPrice: '', highPrice: '' });
  };

  return (
    <Accordion
      brand={brand}
      headingLevel={2}
      id="price-filter-accordion"
      isExpanded={isExpanded}
      onClick={() => setIsExpanded(!isExpanded)}
      theme="secondary-light"
      title="Price"
      titleDescription={getPriceFilterLabel(intlLowPrice, intlHighPrice)}
      variant="boxed"
    >
      <div className={style.wrapper}>
        <InputField
          autoComplete="off"
          brand={brand}
          id="low-price"
          isInvalid={isInvalid.status}
          label="Min"
          onChange={(event) => setLowPrice(numericValue(event.target.value))}
          onKeyDown={onInputFieldKeyDown}
          placeholder="Enter min price"
          type="text"
          value={`${lowPrice ? `$${lowPrice}` : ''}`}
        />
        <span>to</span>
        <InputField
          autoComplete="off"
          brand={brand}
          id="high-price"
          isInvalid={isInvalid.status}
          label="Max"
          onChange={(event) => setHighPrice(numericValue(event.target.value))}
          onKeyDown={onInputFieldKeyDown}
          placeholder="Enter max price"
          type="text"
          value={`${highPrice ? `$${highPrice}` : ''}`}
        />
        {isInvalid?.status && (
        <ErrorMessage id="price-filter-error">{isInvalid.message}</ErrorMessage>
        )}
        { (intlHighPrice !== highPrice || intlLowPrice !== lowPrice) ? (
          <LinkAsButton
            classList={style.firstColumn}
            onClick={applyButtonHandler}
          >
            Apply
          </LinkAsButton>
        ) : null}
        {(lowPrice || highPrice) ? (
          <LinkAsButton
            classList={style.lastColumn}
            onClick={clearButtonHandler}
          >
            Clear
          </LinkAsButton>
        ) : null}
      </div>
    </Accordion>
  );
}

PriceAccordion.propTypes = {
  brand: PropTypes.string.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  lowPrice: PropTypes.string.isRequired,
  highPrice: PropTypes.string.isRequired,
};

export default PriceAccordion;

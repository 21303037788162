import { Link as LinkAsButton } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';

export default function ClearAllButton({ onClick }) {
  const clearAll = useTranslatedText('clearAll', { fallback: 'clear all' });
  return (
    <LinkAsButton onClick={onClick}>
      {clearAll.value}
    </LinkAsButton>
  );
}

ClearAllButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

import { gql } from '@apollo/client';

const DEPARTMENT_DATA = gql`
  query DEPARTMENT_DATA {
    departments {
        id
        name
        defaultCategoryIdBySite
    }
  }
`;

export default DEPARTMENT_DATA;

import { gql, useQuery } from '@apollo/client';
import PropTypes, { string } from 'prop-types';
import React, {
  useCallback,
  useEffect, useReducer,
  useRef,
  useState,
} from 'react';
import { useWindowSize } from 'react-use';
import useCRSParameterListener from '../../hooks/useCRSParameterListener';
import useFilterTriggerAnalytics from '../../hooks/useFilterTriggerAnalytics';
import useStoreData from '../../hooks/useStoreData';
import useUrlSync from '../../hooks/useUrlSync';
import facetDataFragment from '../../queries/fragments/Facets';
import sortDataFragment from '../../queries/fragments/ProductGridHeaderCoreFields';
import DomNodePortal from '../../tools/DomNodePortal';
import { ACTION } from '../../tools/constants';
import getFacetKeysForAnalytics from '../../tools/getFacetKeysForAnalytics';
import getFacetmap from '../../tools/getFacetmap';
import getPageType from '../../tools/getPageType';
import getSelectedFacets from '../../tools/getSelectedFacets';
import getUPrefShipTo from '../../tools/getUPrefShipTo';
import manipulateFacetMap from '../../tools/manipulateFacetMap';
import reducer from '../../tools/parametersChange';
import updateUrl from '../../tools/updateUrl';
import $window from '../../tools/window';
import BreakpointProvider from '../BreakpointProvider';
import CategoryGridWrapper from '../CategoryGridWrapper/CategoryGridWrapper';
import DigitalDataProvider, {
  DD_DISABLE_SWATCH_HOVER,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
  DD_PAGINATION_TEST,
} from '../DigitalDataProvider';
import FacetsLeftRail from '../FacetsLeftRail';
import GroupedCategoryGridWrapper from '../GroupedCategoryGridWrapper';
import { ERROR_MESSAGE } from '../Messages/Messages';
import CategoryGridHeader from '../ProductGridHeader/CategoryGridHeader';
import ProductGridScrollHandler from '../ProductGridScrollHandler';
import StickyRefineBar from '../Refine/StickyRefineBar';
import useStickyIntersection from '../Refine/hooks/useStickyIntersection';
import SingleAEMEspot from '../SingleAemEspot/SingleAemEspot';
import StoreToggle from '../StoreToggle';
import CategoryBottomEspot from './CategoryBottomEspot';
import CategoryHeroEspot from './CategoryHeroEspot';
import GridCategoryEndEspot from './GridCategoryEndEspot';

const getCategoryData = gql`
${sortDataFragment}
${facetDataFragment}
query SortOption($categoryId: String!, $facet: [String]) {
  category(categoryId: $categoryId, facet: $facet) {
    breadcrumbTrailEntryView {
      label
      value
      url
    }
    categoryId
    facets {
      ...Facets
    }
    sortData {
      ...SortData
    }
    leftRailCatNavFlag
    navParentCategoryId
    name
    popularSearches {
      name
      url
    }
    title
    searchURL
    url
  }
}
`;

const getStoreAttributeData = gql`
  query storeAttributes {
    config {
      hasCatalogMfeEspots: genericType(name: "hasCatalogMfeEspots")
      hasCategoryFacetsEnabled: genericType(name: "hasCategoryFacetsEnabled")
      hasShopMyStoreEnabled: genericType(name: "hasLISFilter")
      hasCategoryV3APIEnabled: genericType(name: "hasCategoryV3APIEnabled")
    }
  }
`;

function CategoryPage({
  brand: intlBrand,
  categoryId: intlCategoryId,
  facet: intlFacet = [],
  filter: intlFilter = '',
  grouped = false,
  highPrice: intlHighPrice = '',
  lowPrice: intlLowPrice = '',
  rows: intlRows = '90',
  sort: intlSort = '',
  start: intlStart = '0',
  storePreview = '',
}) {
  const intlFacetArray = typeof intlFacet === 'string' ? [intlFacet] : intlFacet;
  const [parameters, dispatch] = useReducer(reducer, {
    brand: intlBrand,
    categoryId: intlCategoryId,
    facet: intlFacetArray,
    filter: intlFilter,
    highPrice: intlHighPrice,
    lowPrice: intlLowPrice,
    rows: intlRows,
    sort: intlSort,
    start: intlStart,
  });
  const gridWrapper = useRef(null);
  const { width } = useWindowSize();
  const gridIntersecting = useStickyIntersection(gridWrapper);

  const [categoryData, setCategoryData] = useState();
  const [pageBottomStartParameter, setPageBottomStartParameter] = useState(
    parseInt(parameters.start, 10),
  );
  const [pageTopStartParameter, setPageTopStartParameter] = useState(
    parseInt(parameters.start, 10),
  );
  const [productTotalCount, setProductTotalCount] = useState(0);
  const storeDetails = useStoreData();

  // Get ship to value from uPref cookie
  const uPrefShipTo = getUPrefShipTo();
  const { data: storeAttributeData } = useQuery(getStoreAttributeData);

  const {
    data, previousData, loading, error,
  } = useQuery(getCategoryData, {
    variables: {
      categoryId: intlCategoryId,
      facet: parameters.facet,
    },
  });

  const hasShopMyStoreEnabled = storeAttributeData?.config?.hasShopMyStoreEnabled?.value ?? false;
  const hasCategoryV3APIEnabled = storeAttributeData?.config?.hasCategoryV3APIEnabled?.value
    ?? false;

  useUrlSync(parameters, dispatch);

  const handleProductCount = (count) => {
    if (count !== undefined) {
      setProductTotalCount(count);
      // dispatch to CRS to update ANF object there
      $window.dispatchEvent(new CustomEvent('mfe:updateProductCount', { detail: count }));
    }
  };

  const handleCRSParameterChange = useCallback((event) => {
    dispatch({
      type: ACTION.CRS_UPDATE,
      payload: event.detail,
    });
  }, [dispatch]);

  const handleStoreFilter = useCallback((storeId) => {
    dispatch({
      type: ACTION.LOCAL_STORE_TOGGLE,
      payload: storeId,
    });
  }, [dispatch]);

  const handleSortChange = (event) => {
    dispatch({
      type: ACTION.SORT_UPDATE,
      payload: event.target.value,
    });
    $window.digitalData.trigger(`${getPageType()}_sort_applied`, {
      event_name: 'sort_applied',
      event_type: 'sort',
      search_filter_applied_value_name: event.target.value,
      tealium_event: 'sort_applied',
    });
  };

  const onPaginationButtonClick = (event, start) => {
    dispatch({
      type: ACTION.PAGINATION,
      payload: start,
    });
  };

  const onClearAllBtnClick = () => {
    dispatch({
      type: ACTION.CLEAR_ALL_PARAMETERS,
    });
  };

  const handleCheckBoxChange = (event) => {
    const facetMap = getFacetmap(event, parameters.facet);

    dispatch({
      type: ACTION.FACET_TOGGLE,
      payload: [...facetMap],
    });

    if ($window.digitalData) {
      const analyticsEvent = {
        search_filter_applied_category: `${[...getFacetKeysForAnalytics(facetMap)]}`,
        search_filter_applied_value_name: `${[...facetMap.values()]}`,
        event_name: 'filter_applied',
        event_type: 'filters',
        tealium_event: 'filter_applied',
      };
      $window.digitalData.trigger('search_filter_applied', analyticsEvent);
    }
  };

  /* Start Load More Test */

  const handleCategoryData = (returnedData) => {
    setCategoryData(returnedData);
    const initialStart = parseInt(parameters.start, 10);
    setPageBottomStartParameter(initialStart);
    setPageTopStartParameter(initialStart);
  };

  const appendCategoryData = (newProducts) => {
    setCategoryData((prevProducts) => [...prevProducts, ...newProducts]);
  };

  const prependCategoryData = (prependedProducts) => {
    setCategoryData((prevProducts) => [...prependedProducts, ...prevProducts]);
  };

  const handleLoadMoreButtonClick = (newPageBottomStart) => {
    setPageBottomStartParameter(newPageBottomStart);
  };

  const handleLoadPreviousButtonClick = (newPageTopStart) => {
    setPageTopStartParameter(newPageTopStart);
  };

  useEffect(() => {
    const handleMarketingFiltersClicked = (event) => {
      const { detail } = event;
      const { facetGenericName, facetLegacyName } = detail;
      const facetId = hasCategoryV3APIEnabled ? facetGenericName : facetLegacyName;
      const updatedFacetMap = manipulateFacetMap(parameters.facet, { ...detail, facetId });

      dispatch({
        type: ACTION.FACET_TOGGLE,
        payload: [...updatedFacetMap],
      });
    };
    $window.addEventListener('marketingFiltersClicked', handleMarketingFiltersClicked);

    return () => {
      $window.removeEventListener('marketingFiltersClicked', handleMarketingFiltersClicked);
    };
  });

  useEffect(() => {
    if (pageBottomStartParameter > 0
      && pageBottomStartParameter !== parseInt(parameters.start, 10)) {
      updateUrl({
        ...parameters,
        persistHistoryState: true,
        start: pageBottomStartParameter,
      });
    }
    // When parameters change we want start to reset to zero instead of triggering this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageBottomStartParameter]);

  /* End Load More Test */

  useFilterTriggerAnalytics(storeDetails, parameters.filter);

  const hasCategoryFacetsEnabled = storeAttributeData?.config?.hasCategoryFacetsEnabled?.value
    ?? false;
  const hasCatalogMfeEspots = storeAttributeData?.config?.hasCatalogMfeEspots?.value ?? false;

  const handleCategoryParametersEvent = useCallback((event) => {
    // if facets are on the only possible event we can get from CRS would be a store filter change
    if (hasCategoryFacetsEnabled) {
      const storeId = event.detail.filter?.split(':')[1] ?? '';
      handleStoreFilter(storeId);
    } else {
      handleCRSParameterChange(event);
    }
  }, [hasCategoryFacetsEnabled, handleCRSParameterChange, handleStoreFilter]);
  // Need this for backward compatibility
  useCRSParameterListener(handleCategoryParametersEvent);

  if (error) {
    console.error('CategoryPage : getCategoryData : Error executing GraphQL query', {
      categoryIdParameter: intlCategoryId,
    });

    return ERROR_MESSAGE;
  }

  let currentData;

  if (loading) {
    if (!previousData) {
      return null;
    }
    currentData = previousData;
  } else {
    currentData = data;
  }

  const {
    brand,
    categoryId,
    facet,
    filter,
    rows,
    sort,
    start,
  } = parameters;
  const {
    breadcrumbTrailEntryView,
    facets: facetData,
    leftRailCatNavFlag,
    navParentCategoryId,
    name,
    popularSearches,
    sortData,
    title,
    url,
  } = currentData.category;

  const mobileGridHeaderFlag = leftRailCatNavFlag;

  const isDesktop = width > 1025;
  const isFacetSelected = parameters.facet?.length > 0;
  const renderFacets = facetData?.length > 0 && hasCategoryFacetsEnabled;
  const isStickyBarShowing = (hasCategoryFacetsEnabled && gridIntersecting && !isDesktop);

  const onClearFacetTag = (payload) => {
    if (hasCategoryFacetsEnabled) {
      const facetMap = getSelectedFacets(parameters.facet);
      // Check if selected checkbox is in already selected facet filters
      if (facetMap.has(payload.facetKey)) {
        const checkedFacets = facetMap.get(payload.facetKey);
        const index = checkedFacets.indexOf(payload.facetValue);
        if (index !== -1) {
          if (checkedFacets.length === 1) {
            facetMap.delete(payload.facetKey);
          } else {
            checkedFacets.splice(index, 1);
          }
        }
      }
      dispatch({
        type: ACTION.FACET_TOGGLE,
        payload: [...facetMap],
      });
    } else {
      const correspondingLeftRailFilterInput = document.querySelector(
        `input[name="${payload.facetKey}"][value="${payload.facetValue}"],
          input[name="${payload.facetValue}"][value="${payload.facetKey},${payload.facetValue}"]`,
      );
      if (correspondingLeftRailFilterInput && correspondingLeftRailFilterInput.checked) {
        correspondingLeftRailFilterInput.click();
      }
    }
  };

  return (
    <BreakpointProvider>
      <DigitalDataProvider
        keys={[
          DD_DISABLE_SWATCH_HOVER,
          DD_HYPERLINK_DESC,
          DD_MODEL_IMAGERY_TEST,
          DD_PAGINATION_TEST,
        ]}
      >
        {hasCatalogMfeEspots && (
          <>
            <CategoryHeroEspot brand={brand} categoryId={categoryId} />
            <SingleAEMEspot espotId={`${categoryId}-${brand.toUpperCase()}-cmp`} />
          </>
        )}
        <ProductGridScrollHandler parameters={parameters}>
          <DomNodePortal targetNodeSelector=".store-toggle-container-target">
            {hasShopMyStoreEnabled && (
              <>
                <StoreToggle
                  handleStoreFilter={handleStoreFilter}
                  isShopMyStore={filter !== ''}
                  storeDetails={storeDetails}
                />
                <hr />
              </>
            )}
          </DomNodePortal>
          <DomNodePortal targetNodeSelector=".sort-and-filter-facet-container">
            {renderFacets && (
              <FacetsLeftRail
                brand={brand}
                facet={facet}
                facetData={facetData}
                isCategoryPage
                onCheckBoxChange={handleCheckBoxChange}
                popularSearches={popularSearches}
              />
            )}
          </DomNodePortal>
          <CategoryGridHeader
            brand={brand}
            categoryId={intlCategoryId}
            categoryName={name}
            categoryTitle={title}
            categoryUrl={url}
            facet={facet}
            facetData={facetData}
            filter={filter}
            handleStoreFilter={handleStoreFilter}
            hasCategoryFacetsEnabled={hasCategoryFacetsEnabled}
            hasSelectedCategoryFilters={filter !== '' || facet?.length > 0}
            hasShopMyStoreEnabled={hasShopMyStoreEnabled}
            isCategoryPage
            isFacetSelected={isFacetSelected}
            leftRailMobileFlag={hasCategoryFacetsEnabled}
            mobileGridHeaderFlag={mobileGridHeaderFlag}
            navParentCategoryId={navParentCategoryId}
            onCheckBoxChange={handleCheckBoxChange}
            onClearAllBtnClick={onClearAllBtnClick}
            onFacetTagClick={onClearFacetTag}
            onSortChange={handleSortChange}
            resultsCount={productTotalCount}
            selectedSort={sort || sortData.defaultSortOption}
            sortData={sortData}
            storeDetails={storeDetails}
          />
          <div ref={gridWrapper}>
            {isStickyBarShowing && (
              <StickyRefineBar
                brand={brand}
                breadcrumbTrailEntryView={breadcrumbTrailEntryView}
                facetData={facetData}
                isCategoryPage
                isFacetSelected={isFacetSelected}
                leftRailMobileFlag={hasCategoryFacetsEnabled}
                onCheckBoxChange={handleCheckBoxChange}
                onClearAllBtnClick={onClearAllBtnClick}
                onSortChange={handleSortChange}
                resultsCount={productTotalCount}
                selectedSort={parameters.sort || sortData.defaultSortOption}
                sortData={sortData}
              />
            )}
            {(grouped) ? (
              <GroupedCategoryGridWrapper
                key={`${categoryId}-Grouped-Page`}
                categoryId={categoryId}
                facet={facet}
                filter={filter}
                handleProductCount={handleProductCount}
                highPrice={intlHighPrice}
                isShopMyStore={parameters.filter !== ''}
                lowPrice={intlLowPrice}
                onPaginationButtonClick={onPaginationButtonClick}
                rows={rows}
                sort={sort}
                start={start}
                storePreview={storePreview}
                uPrefShipTo={uPrefShipTo}
              />
            )
              : (
                <CategoryGridWrapper
                  key={`${categoryId}-Page`}
                  categoryId={categoryId}
                  facet={facet}
                  filter={filter}
                  handleProductCount={handleProductCount}
                  highPrice={intlHighPrice}
                  isShopMyStore={parameters.filter !== ''}
                  lowPrice={intlLowPrice}
                  onPaginationButtonClick={onPaginationButtonClick}
                  rows={rows}
                  sort={sort}
                  start={start}
                  storePreview={storePreview}
                  uPrefShipTo={uPrefShipTo}
                  // Load More test props
                  /* eslint-disable react/jsx-sort-props */
                  categoryData={categoryData}
                  handleCategoryData={handleCategoryData}
                  appendCategoryData={appendCategoryData}
                  prependCategoryData={prependCategoryData}
                  handleLoadMoreButtonClick={handleLoadMoreButtonClick}
                  handleLoadPreviousButtonClick={handleLoadPreviousButtonClick}
                  pageTopStartParameter={pageTopStartParameter}
                  pageBottomStartParameter={pageBottomStartParameter}
                />
              )}
          </div>

          {hasCatalogMfeEspots && (
            <>
              <CategoryBottomEspot brand={brand} categoryId={categoryId} />
              <GridCategoryEndEspot categoryId={categoryId} brand={brand} />
            </>
          )}
        </ProductGridScrollHandler>
      </DigitalDataProvider>
    </BreakpointProvider>

  );
}

CategoryPage.propTypes = {
  // Required props
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  // Optional props
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  grouped: PropTypes.bool,
  highPrice: PropTypes.string,
  lowPrice: PropTypes.string,
  rows: PropTypes.string,
  sort: PropTypes.string,
  start: PropTypes.string,
  storePreview: PropTypes.string,
};

export default CategoryPage;

import PropTypes from 'prop-types';
import React from 'react';

const Loader = ({
  variant = '',
}) => {
  // TODO: Add TMNT for screen reader text
  if (variant === 'dots') {
    return (
      <span className="loader " data-variant="dots">
        <span />
        <span />
        <span className="screen-reader-text">Loading</span>
      </span>
    );
  }
  return null;
};

Loader.propTypes = {
  variant: PropTypes.string,
};

export default Loader;

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import SearchResultsContext from '../../context/SearchResultsContext';
import BreakpointContext from '../../context/breakpoint';
import { IMAGE_HOST_NAME } from '../../queries';
import { SIMILAR_PRODUCT_ROWS } from '../../tools/constants';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import Slider from '../Slider';
import NoResults from './NoResults';
import { SIMILAR_SLIDER_RESULTS } from './queries';
import style from './styles/similarModal.module.scss';
import { getDesktopProductCards, getMobileProductCards } from './tools/getProductCards';
import removeLastAttribute from './tools/removeLastAttribute';

const SimilarSlider = ({
  facet = [],
  keyword,
  productId,
  rows = SIMILAR_PRODUCT_ROWS,
  setIsQuickViewOpen = () => { },
}) => {
  const { large: isDesktop } = useContext(BreakpointContext);
  const {
    brand,
    countryFulfillmentStore,
    departmentId,
    storePreview,
  } = useContext(SearchResultsContext);

  const searchParameters = {
    brand,
    countryFulfillmentStore,
    departmentId,
    facet,
    rows: (parseInt(rows, 10) + 5).toString(),
    searchTerm: keyword,
    storePreview,
  };

  const searchResultsData = useQuery(SIMILAR_SLIDER_RESULTS, {
    variables: searchParameters,
  });
  const searchResults = searchResultsData.data?.searchResults;
  const resultsCount = searchResults?.products?.length;
  const isResultCountLow = resultsCount < rows && resultsCount < 10;

  const lowResultsResponse = useQuery(SIMILAR_SLIDER_RESULTS, {
    variables: { ...searchParameters, searchTerm: removeLastAttribute(keyword) },
    skip: !isResultCountLow || searchResultsData.loading,
  });

  const imageHost = useQuery(IMAGE_HOST_NAME);

  if (searchResultsData.loading
    || lowResultsResponse.loading || imageHost.loading) return LOADING_MESSAGE;
  if (searchResultsData.error
    || lowResultsResponse.error || imageHost.error) return ERROR_MESSAGE;

  const products = isResultCountLow
    ? (lowResultsResponse.data?.searchResults?.products || [])
    : searchResults?.products;

  const productCardData = getDesktopProductCards(
    products,
    setIsQuickViewOpen,
    productId,
    brand,
    rows,
    imageHost.data.imageHost.imageHostName,
  );

  if (productCardData.length === 0) return <NoResults />;

  const cards = getMobileProductCards(productCardData);

  return (
    <>
      {isDesktop
        ? (
          <Slider
            slideContent={productCardData}
          />
        )
        : (
          <div className={style.grid}>
            {cards}
          </div>
        )}
    </>
  );
};
SimilarSlider.propTypes = {
  facet: PropTypes.arrayOf(PropTypes.string),
  keyword: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  rows: PropTypes.string,
  setIsQuickViewOpen: PropTypes.func,
};

export default SimilarSlider;

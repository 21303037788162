import { Breadcrumb } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import useDigitalData from '../../hooks/useDigitalData';
import { DD_ENHANCED_STICKY_BAR } from '../DigitalDataProvider';
import NumberOfResults from '../NumberOfResults/NumberOfResults';
import RefineButton from './RefineButton';
import style from './StickyRefine.module.scss';

function StickyRefineBar({
  brand,
  breadcrumbTrailEntryView = [],
  facet = [],
  facetData = [],
  filter = '',
  hasPriceFilterEnabled = false,
  highPrice = '',
  isCategoryPage = false,
  isFacetSelected = false,
  leftRailMobileFlag = false,
  lowPrice = '',
  onCheckBoxChange = () => {},
  onClearAllBtnClick = () => {},
  onPriceChange = () => {},
  onSortChange = () => {},
  resultsCount = null,
  searchTerm = '',
  selectedSort = '',
  sortData = {
    sortOptions: [],
  },
}) {
  const getBreadCrumbs = () => breadcrumbTrailEntryView.slice(1, 4).map((breadCrumb) => {
    const { label, url } = breadCrumb;
    return {
      href: url,
      label,
    };
  });
  const {
    [DD_ENHANCED_STICKY_BAR]: hasEnhancedStickyBarEnabled,
  } = useDigitalData([
    DD_ENHANCED_STICKY_BAR,
  ]);

  return (
    <div className={style.stickyBar}>
      {breadcrumbTrailEntryView.length > 0 && (
        <Breadcrumb
          crumbs={getBreadCrumbs()}
          labelText="Breadcrumb"
        />
      )}
      {searchTerm && hasEnhancedStickyBarEnabled && (
        <div className={style.overFlowHidden}>
          <h2 className={style.textDetails}>
            &quot;
            {searchTerm}
            &quot;
          </h2>
          <div>
            <NumberOfResults count={resultsCount} numberFirst />
          </div>
        </div>
      )}
      <RefineButton
        brand={brand}
        facet={facet}
        facetData={facetData}
        filter={filter}
        hasIconOnly
        hasPriceFilterEnabled={hasPriceFilterEnabled}
        highPrice={highPrice}
        isCategoryPage={isCategoryPage}
        isFacetSelected={isFacetSelected}
        leftRailMobileFlag={leftRailMobileFlag}
        lowPrice={lowPrice}
        onCheckBoxChange={onCheckBoxChange}
        onClearAllBtnClick={onClearAllBtnClick}
        onPriceChange={onPriceChange}
        onSortChange={onSortChange}
        resultsCount={resultsCount}
        selectedSort={selectedSort}
        sortData={sortData}
      />
    </div>
  );
}

StickyRefineBar.propTypes = {
  brand: PropTypes.string.isRequired,
  breadcrumbTrailEntryView: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  isCategoryPage: PropTypes.bool,
  leftRailMobileFlag: PropTypes.bool,
  onSortChange: PropTypes.func,
  onPriceChange: PropTypes.func,
  highPrice: PropTypes.string,
  hasPriceFilterEnabled: PropTypes.bool,
  lowPrice: PropTypes.string,
  searchTerm: PropTypes.string,
  selectedSort: PropTypes.string,
  sortData: PropTypes.shape({
    sortOptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })),
  }),
  resultsCount: PropTypes.number,
  facetData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isFacetSelected: PropTypes.bool,
  onClearAllBtnClick: PropTypes.func,
  onCheckBoxChange: PropTypes.func,
};

export default StickyRefineBar;

import { gql } from '@apollo/client';
import facetsFragment from '../../../queries/fragments/Facets';
import sortDataFragment from '../../../queries/fragments/ProductGridHeaderCoreFields';

const SEARCH_RESULTS_PAGE_DATA = gql`
${sortDataFragment}
${facetsFragment}
query Search(
  $departmentId: String,
  $initialDepartmentId: String,
  $facet: [String],
  $searchTerm: String!
  $filter: String
  $lowPrice: String
  $highPrice: String
  $initialSearchTerm: String
  ) {
  searchResults(
    departmentId: $departmentId,
    initialDepartmentId: $initialDepartmentId,
    facet: $facet,
    searchTerm: $searchTerm,
    filter: $filter,
    lowPrice: $lowPrice,
    highPrice: $highPrice,
    initialSearchTerm: $initialSearchTerm
  ) {
    sortData {
      ...SortData
    }
    facets {
      ...Facets
    }
    isElasticSearchEnabled
    stats {
      total
      refinedTotal
    }
    departmentId
    searchSuggestions {
      byName {
        rankedWords {
          words
        }
        rankedPhrases {
          phrases
        }
      }
      byAttributes {
        rankedColors {
          words
        }
        rankedNames {
          words
        }
        rankedStyles {
          words
        }
      }
    }
    suggestedSearchTerm
  }
}
`;

const STORE_ATTRIBUTES = gql`
  query storeAttributes {
    config {
      facetsLeftRailFlag: genericType(name: "hasFacetsLeftRailEnabled")
      leftRailMobileFlag: genericType(name: "hasFacetsLeftRailMobileEnabled")
      hasShopMyStoreEnabled: genericType(name: "hasLISFilter")
    }
  }
`;

export { SEARCH_RESULTS_PAGE_DATA, STORE_ATTRIBUTES };

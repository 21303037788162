import { Tabs } from 'anf-core-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import style from './CategoryRecommendations.module.scss';
import CategoryRecommendationsPanel from './CategoryRecommendationsPanel';

const isTitlePlacement = ({ id }) => id === 'home_page.rr8' || id === 'home_page.m_rr8';

function CategoryRecommendationsTabs({ placementData }) {
  const initialTabOrder = placementData
    .filter((placement) => !isTitlePlacement(placement))
    .map(({ id }) => id);
  const [selectedTab, setSelectedTab] = useState(initialTabOrder[0]);
  const titlePlacement = placementData.find(isTitlePlacement);
  const title = titlePlacement?.title || 'Picked For You';

  const handleKeyDown = (event) => {
    const currentIndex = initialTabOrder.indexOf(selectedTab);
    if (event.key === 'ArrowRight' && currentIndex < initialTabOrder.length - 1) {
      setSelectedTab(initialTabOrder[currentIndex + 1]);
    } else if (event.key === 'ArrowLeft' && currentIndex > 0) {
      setSelectedTab(initialTabOrder[currentIndex - 1]);
    }
  };

  const handleTabClick = (id, tabTitle) => {
    setSelectedTab(id);
    if (window.digitalData) {
      window.digitalData.trigger('universal_click', {
        event_name: 'click',
        data_text: `category recs - ${tabTitle}: click`,
        data_action: 'click',
      });
    }
  };

  const categoryTabs = () => placementData
    .filter((placement) => !isTitlePlacement(placement))
    .map(({ id, title: tabTitle }) => ({
      ariaControls: `${id}-tabpanel`,
      id: `${id}-tab`,
      isSelected: selectedTab === id,
      label: tabTitle,
      onClick: () => handleTabClick(id, tabTitle),
    }));

  return (
    <div className={classNames('scope-1892', style.container)}>
      <h1 className={style.recommendationsTitle}>{title}</h1>
      <Tabs
        controls={{
          onKeyDown: handleKeyDown,
          tabs: categoryTabs(),
        }}
        labelText="Category Recommendations"
      >
        {placementData
          .filter((placement) => !isTitlePlacement(placement))
          .map((placement) => (
            <CategoryRecommendationsPanel
              key={placement.id}
              placement={placement}
              selectedTab={selectedTab}
            />
          ))}
      </Tabs>
    </div>
  );
}

export default CategoryRecommendationsTabs;

CategoryRecommendationsTabs.propTypes = {
  placementData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
    }),
  ).isRequired,
};

import { gql, useQuery } from '@apollo/client';
import Logo from 'anf-core-react/components/Logo';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import DomNodePortal from '../../tools/DomNodePortal';
import $window from '../../tools/window';
import DigitalDataProvider, {
  DD_SHOW_ANF_NAVIGATION_L3S_TEST,
} from '../DigitalDataProvider';
import { LOADING_MESSAGE, ERROR_MESSAGE } from '../Messages/Messages';
import style from './LargeScreenNavBar.module.scss';
import LargeScreenNavBarCategory from './LargeScreenNavBarCategory';

const getSubcategoriesForCategory = gql`
  query Category($categoryId: String!, $storePreview: String) {
    category(categoryId: $categoryId, storePreview: $storePreview) {
      brand
      brandLabel
      categoryId
      mainNavLogoData {
        tagType
        largeScreen
        largeScreen2x
        largeScreenPoster
      }
      brandLogoType
      url
      navSpecialInteractionCategories
      subCategories(hideCategory: false) {
        categoryId
        espotIdentifier
        isFullWidthFlyout
        name
        url
        topNavEspotContent {
          espotId
          htmlContent
        }
        subCategories(displayTypes: ["regular", "clearance", "featured"], expandByDisplayTypes: ["regular"]) {
          categoryId
          displayType
          name
          url
          subCategories {
            categoryId
            name
            url
          }
        }
      }
    }
  }
`;

/*
  We'll be feeding this component a top level (aka "L0") categoryId prop,
  meaning that the subcategories that we'll be iterating through will
  be our L1 categories!
*/
const LargeScreenNavBar = ({ categoryId, storePreview }) => {
  const l1Container = useRef();
  const mainMenuLabel = useTranslatedText('mainMenuLabel', { fallback: 'Main Menu' });

  const handleWrappingListItems = () => {
    const l1List = l1Container.current;
    const l1Items = l1List?.querySelectorAll('.nav-bar-category-list-item') || null;
    if (l1Items) {
      l1Items.forEach((l1Item, l1ItemIndex) => {
        if (l1Item.offsetTop !== 0) {
          l1Items[l1ItemIndex].style.visibility = 'hidden';
        } else {
          l1Items[l1ItemIndex].style.visibility = 'visible';
        }
      });
    }
  };

  // On mount and window resize, hide any wrapping L1 list items
  useEffect(() => {
    $window.addEventListener('resize', handleWrappingListItems);
    handleWrappingListItems();

    return () => $window.removeEventListener('resize', handleWrappingListItems);
  }, []);

  const openMenuText = useTranslatedText('openMenu', { fallback: 'Open Menu' });
  const allText = useTranslatedText('all', { fallback: 'All' });

  const { loading, error, data } = useQuery(getSubcategoriesForCategory, {
    errorPolicy: 'ignore',
    variables: {
      categoryId,
      storePreview,
    },
  });

  const [openCategoryId, setOpenCategoryId] = useState(null);
  const isBackdropVisible = !!openCategoryId;

  if (loading) {
    return <div>{LOADING_MESSAGE}</div>;
  }

  if (error || !data?.category) {
    return <div>{ERROR_MESSAGE}</div>;
  }

  const customLogo = data.category.mainNavLogoData;
  let logoContent;

  if (customLogo && customLogo.largeScreen) {
    if (customLogo.tagType === 'video') {
      logoContent = (
        <video
          className="custom-logo"
          muted
          playsInline
          poster={customLogo.largeScreenPoster}
        >
          <source data-res="1x" data-src={customLogo.largeScreen} type="video/mp4" />
          <source data-res="2x" data-src={customLogo.largeScreen2x} type="video/mp4" />
        </video>
      );
    } else {
      logoContent = <img alt={data.category.brandLabel} className="custom-logo" src={customLogo.largeScreen} />;
    }
  } else {
    logoContent = data.category.brandLogoType ? <Logo kind={data.category.brandLogoType} /> : null;
  }

  return (
    <DigitalDataProvider
      keys={[
        DD_SHOW_ANF_NAVIGATION_L3S_TEST,
      ]}
    >
      <>
        <div className="large-screen-nav-container">
          <a aria-label={data.category.brand} className="logo-link" href={data.category.url}>
            {logoContent}
          </a>
          <nav aria-label={mainMenuLabel?.value} className="large-screen-nav-bar" data-testid="main-menu-nav">
            <ul ref={l1Container} data-level="1">
              {data.category.subCategories?.map((category) => category
                && category.categoryId && (
                  <LargeScreenNavBarCategory
                    key={category.categoryId}
                    allText={allText.value}
                    brand={data.category.brand ?? ''}
                    category={category}
                    handleWrappingListItems={handleWrappingListItems}
                    hasAemLink={
                      data
                        .category.navSpecialInteractionCategories?.includes(category.categoryId)
                        ?? false
                    }
                    openCategoryId={openCategoryId ?? ''}
                    openMenuText={openMenuText.value}
                    setOpenCategoryId={setOpenCategoryId}
                    storePreview={storePreview ?? ''}
                  />
              ))}
            </ul>
          </nav>
        </div>
        <DomNodePortal targetNodeSelector="#catalog-mfe-header-backdrop">
          <div className={classNames(
            style.backdrop,
            {
              [style.backdropVisible]: isBackdropVisible,
            },
          )}
          />
        </DomNodePortal>
      </>
    </DigitalDataProvider>
  );
};

LargeScreenNavBar.propTypes = {
  categoryId: PropTypes.string.isRequired,
  storePreview: PropTypes.string.isRequired,
};

export default LargeScreenNavBar;

// Return boolean value corresponding to valid parameter value(s)
const parseBoolean = (parameterValue, optional = false) => {
  if (optional && typeof parameterValue === 'undefined') {
    return parameterValue;
  }

  return parameterValue === 'true';
};

// Return integer value corresponding to valid parameter value(s)
// Not used currently but here if needed
/* const parseInteger = (parameterValue, optional = false) => {
  if (optional && typeof parameterValue === 'undefined') {
    return parameterValue;
  }

  return Number.parseInt(parameterValue, 10);
}; */

/**
 * Return object of React component props corresponding to MFE endpoint URL parameters, in which
 * each parameter string is parsed to an appropriate type and value
 * - query parameter should be an object containing Koa request query parameter properties
 *   See https://github.com/koajs/koa/blob/2.13.4/docs/api/request.md#requestquery.
 */
const parseEndpointParams = ({
  brand,
  catalogId,
  categoryId,
  country,
  countryFulfillmentStore,
  currentCategoryId,
  departmentId,
  initialDepartmentId,
  espotId,
  facet,
  filter,
  grouped,
  hasLeftRail,
  highPrice,
  langId,
  lowPrice,
  originalSearchTerm,
  mySavesPath,
  parentCategoryId,
  productId,
  rows,
  searchTerm,
  searchUrl,
  sort,
  start,
  storePreview,
}) => ({
  brand,
  catalogId,
  categoryId,
  country,
  countryFulfillmentStore,
  currentCategoryId,
  departmentId,
  initialDepartmentId,
  espotId,
  facet,
  filter,
  grouped: parseBoolean(grouped),
  hasLeftRail,
  highPrice,
  langId,
  lowPrice,
  userInputedSearchTerm: originalSearchTerm,
  mySavesPath,
  parentCategoryId,
  productId,
  rows,
  searchTerm,
  searchUrl,
  sort,
  start,
  storePreview,
});

export { parseEndpointParams as default, parseBoolean };

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import GET_MFE_COMPONENT from './queries';

const StandaloneMfe = ({ componentName, mfeName }) => {
  const { data } = useQuery(GET_MFE_COMPONENT, {
    variables: { mfeName, componentName },
  });

  if (!data?.standaloneMfe?.componentHtml) {
    return null;
  }

  return (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{ __html: data.standaloneMfe.componentHtml }} />
  );
};

StandaloneMfe.propTypes = { componentName: PropTypes.string.isRequired };
StandaloneMfe.propTypes = { mfeName: PropTypes.string.isRequired };

export default StandaloneMfe;

import PropTypes from 'prop-types';
import React from 'react';
import indexesOf from '../../tools/indexesOf';

export default function AutoSuggestSubList({
  suggestionType = '',
  data,
  listPosition = 0,
  listItemSubmitHandler,
  searchTerm = '',
}) {
  const listItemSubmit = (event) => {
    if (event.type === 'click' || event.key === 'Enter') {
      listItemSubmitHandler(event?.currentTarget?.dataset, suggestionType);
    }
  };

  const subList = data.map((item, index) => {
    const currentValue = item.name;

    const [startIndex, endIndex] = indexesOf(currentValue, searchTerm);

    const departmentSuggestionWrapper = (listItem) => (
      <li
        key={currentValue}
        aria-label={currentValue}
        aria-selected={index === listPosition}
        className="suggestion-list-item"
        data-departmentid={item.departmentIdentifier}
        data-index={index}
        data-type={suggestionType}
        data-value={currentValue}
        onClick={listItemSubmit}
        onKeyDown={listItemSubmit}
        role="option"
      >
        {listItem}
      </li>
    );

    const productSuggestionWrapper = (listItem) => (
      <li
        key={currentValue}
        aria-label={currentValue}
        aria-selected={index === listPosition}
        className="suggestion-list-item"
        data-index={index}
        data-type={suggestionType}
        data-value={currentValue}
        onClick={listItemSubmit}
        onKeyDown={listItemSubmit}
        role="option"
      >
        {listItem}
      </li>
    );

    const subListItem = (
      <>
        <span
          aria-hidden="true"
          className="search-item"
        >
          <span className="list-item-content">
            <span className="auto-suggest-list-item-label">
              {currentValue.slice(0, startIndex)}
              <span className="highlighted-text">
                {currentValue.substring(startIndex, endIndex + 1)}
              </span>
              {currentValue.slice(endIndex + 1)}
            </span>
          </span>
        </span>
        <span className="link-arrow" />
      </>
    );

    return item.departmentIdentifier
      ? departmentSuggestionWrapper(subListItem)
      : productSuggestionWrapper(subListItem);
  });

  return (
    <ul className="auto-suggest-list-menu">
      {subList}
    </ul>
  );
}

AutoSuggestSubList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      suggestedTerm: PropTypes.string,
    }),
  ).isRequired,
  listItemSubmitHandler: PropTypes.func.isRequired,
  listPosition: PropTypes.number,
  searchTerm: PropTypes.string,
  suggestionType: PropTypes.string,
};

import { useQuery } from '@apollo/client';
import { useLocalStorage } from 'react-use';
import {
  CLICKED_SUGGESTION_KEY,
} from '../../../tools/constants';
import { SEARCH_RESULTS_PAGE_DATA } from '../queries';
import triggerAnalytics from '../triggerAnalytics';

const useSearchResponse = (parameters, suggestedSearchterm, userInputedSearchTerm) => {
  const [completionType] = useLocalStorage(CLICKED_SUGGESTION_KEY, []);
  const isInitialSearch = parameters.submitMethod === 'toaster';
  const searchResponse = useQuery(SEARCH_RESULTS_PAGE_DATA, {
    variables: {
      searchTerm: parameters.searchTerm,
      facet: parameters.facet,
      departmentId: parameters.departmentId,
      initialDepartmentId: parameters.initialDepartmentId,
      filter: parameters.filter,
      lowPrice: parameters.lowPrice,
      highPrice: parameters.highPrice,
      initialSearchTerm: parameters.initialSearchTerm,
    },
    onCompleted: (searchResultsData) => {
      // update suggested search term without rerendering
      // eslint-disable-next-line no-param-reassign
      suggestedSearchterm.current = searchResultsData?.searchResults?.suggestedSearchTerm ?? '';
      triggerAnalytics({
        completionType,
        isInitialSearch,
        previousSearchterm: parameters.previousSearchterm,
        resultCount: searchResultsData?.searchResults?.stats?.total || 0,
        searchTerm: parameters.searchTerm,
        userInputedSearchTerm: isInitialSearch ? userInputedSearchTerm : parameters.searchTerm,
      });
    },
    skip: !parameters.searchTerm,
  });

  const {
    data, previousData, loading, error,
  } = searchResponse;

  return {
    data,
    previousData,
    loading,
    error,
  };
};

export default useSearchResponse;

const getSelectedFacets = (facets) => {
  const selectedFacets = new Map();
  let normalizedSelectedFacets;
  facets.forEach((facet) => {
    normalizedSelectedFacets = facet.split(':');
    selectedFacets.set(
      normalizedSelectedFacets[0],
      normalizedSelectedFacets[1].split('" ').map((value) => value.replace(/[(),"]/g, '')),
    );
  });
  return selectedFacets;
};

module.exports = getSelectedFacets;

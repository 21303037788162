import {
  Button,
  DropDown,
  HorizontalSeparator,
  InputField,
  Switch,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';
import { useCopyToClipboard } from 'react-use';
import { SHOP_SIMILAR_RECIPES } from '../../tools/constants';
import SimilarEntryPoint from './SimilarEntryPoint';

const ShopSimilarExample = ({
  brand = '',
  productId = '',
}) => {
  const [exampleTestVariables, setExampleTestVariables] = useState({
    containerId: '',
    facet: [],
    productId,
    rows: '10',
    similarRecipeId: 'default',
    displayFacets: false,
  });
  const [, copyToClipboard] = useCopyToClipboard();
  const [buttonText, setButtonText] = useState('Copy your event details!');
  const handleCopyButton = () => {
    setButtonText('Copied!');
    setTimeout(() => {
      setButtonText('Copy your event details!');
    }, 5000);
    copyToClipboard(JSON.stringify(exampleTestVariables));
  };
  const switchHandler = (anEvent) => {
    setExampleTestVariables((prevState) => ({
      ...prevState,
      [anEvent.target.id]: !prevState[anEvent.target.id],
    }));
  };
  const inputHandler = (anEvent) => {
    setExampleTestVariables((prevState) => ({
      ...prevState,
      [anEvent.target.id]: anEvent.target.value,
    }));
  };
  const handleShopSimilarEvent = () => {
    window.dispatchEvent(new CustomEvent('event:seek-shop-similar', { detail: exampleTestVariables }));
  };
  const handleDropDownChange = (anEvent) => {
    setExampleTestVariables((prevState) => ({
      ...prevState,
      [anEvent.target.id]: anEvent.target.value,
    }));
  };
  return (
    <>
      <SimilarEntryPoint brand={brand} />
      <div
        className="inputwrapper"
        style={{
          display: 'grid',
          gap: '10px',
          gridTemplateColumns: 'repeat(auto-fit, minmax(250px,1fr))',
          margin: '10px',
        }}
      >
        <InputField
          autoComplete="off"
          id="productId"
          isRequired
          label="Product ID"
          onChange={inputHandler}
          value={exampleTestVariables.productId}
        />
        <InputField
          autoComplete="off"
          id="containerId"
          label="Container ID"
          onChange={inputHandler}
          value={exampleTestVariables.containerId}
        />
        <InputField
          autoComplete="off"
          id="facet"
          label="Facet"
          onChange={inputHandler}
          value={exampleTestVariables.facet.toString()}
        />
        <InputField
          autoComplete="off"
          id="rows"
          label="Rows"
          onChange={inputHandler}
          value={exampleTestVariables.rows}
        />
        <DropDown
          id="similarRecipeId"
          label="Similar Recipes"
          onChange={handleDropDownChange}
          options={
            SHOP_SIMILAR_RECIPES.map((recipe) => ({ key: recipe, label: recipe, value: recipe }))
          }
          value={exampleTestVariables.similarRecipeId}
        />
        <div style={{ gridColumn: '1/-1', margin: '20px 0' }}>
          <HorizontalSeparator>
            Not yet implemented
          </HorizontalSeparator>
        </div>
        <Switch
          id="displayFacets"
          isChecked={exampleTestVariables.displayFacets}
          onChange={switchHandler}
        >
          Display Facets
        </Switch>
        <Switch
          id="hasQuickView"
          isChecked={exampleTestVariables.hasQuickView}
          onChange={switchHandler}
        >
          Has Quick View
        </Switch>
      </div>

      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        justifyContent: 'center',
        margin: '20px',
      }}
      >

        <Button
          onClick={handleCopyButton}
          variant="secondary"
        >
          {buttonText}
        </Button>
        <Button
          onClick={() => handleShopSimilarEvent()}
          variant="secondary"
        >
          Launch Shop Similar with Product&nbsp;
          &quot;
          {exampleTestVariables.productId}
          &quot;
        </Button>
      </div>

      {!!exampleTestVariables.containerId && (
        <div
          style={{
            marginTop: '20px',
            padding: '20px',
            backgroundColor: 'lightblue',
          }}
        >
          <span
            id={exampleTestVariables.containerId}
            style={{ background: 'white', display: 'block' }}
          >
            Your container id is&nbsp;
            &quot;
            {exampleTestVariables.containerId}
            &quot;
          </span>
        </div>
      )}
    </>
  );
};

export default ShopSimilarExample;

ShopSimilarExample.propTypes = {
  brand: PropTypes.string,
  productId: PropTypes.string,
};

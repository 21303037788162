import { gql } from '@apollo/client';

const facetsFragment = gql`
  fragment Facets on Facet {
    id
    name
    facetGroups {
      orderNumber
      name
      facetValues {
        id
        name
        enabled
        checked
      }
    }
  }
`;

export default facetsFragment;

import PropTypes from 'prop-types';
import React from 'react';
import SearchDetails from '../SearchDetails/SearchDetails';
import HeaderFilters from './HeaderFilters';

function BaseSearchHeader({
  editableSearchFlag = false,
  facet = [],
  highPrice = '',
  isClearAllButtonEnabled = false,
  lowPrice = '',
  onClearAllBtnClick = () => {},
  onFacetTagClick = () => {},
  onPriceChange = () => {},
  searchTerm,
  count = 0,
}) {
  return (
    <>
      <SearchDetails
        count={count}
        editableSearchFlag={editableSearchFlag}
        searchTerm={searchTerm}
      />
      <HeaderFilters
        facet={facet}
        highPrice={highPrice}
        isClearAllButtonEnabled={isClearAllButtonEnabled}
        lowPrice={lowPrice}
        onClearAllBtnClick={onClearAllBtnClick}
        onFacetTagClick={onFacetTagClick}
        onPriceChange={onPriceChange}
        searchTerm={searchTerm}
      />
    </>
  );
}

BaseSearchHeader.propTypes = {
  editableSearchFlag: PropTypes.bool,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  isClearAllButtonEnabled: PropTypes.bool,
  highPrice: PropTypes.string,
  lowPrice: PropTypes.string,
  onPriceChange: PropTypes.func,
  onClearAllBtnClick: PropTypes.func,
  onFacetTagClick: PropTypes.func,
  searchTerm: PropTypes.string.isRequired,
  count: PropTypes.number,
};

export default BaseSearchHeader;
